import React from "react";
import { ReactComponent as Logo } from '../../assets/img/oh.svg';

export default function Transaction404() {
  return (
    <div className="page-404__wrapper">
      <Logo />
      <h1 className="nk-error-title">Payment Complete</h1>
      <h2 className="page-404__subtitle">
        This payment link has already been used to process a transaction, it cannot be used again.
      </h2>
    </div>
  );
}



