import { IS_EMBEDDED, JOURNEY_PROGRESS, SET_ENUMS, SET_TRANSACTION_ID } from "../action/application";

export const initialState = {
  isEmbedded: false,
  journeyProgress: null,
  transactionID: null,
  enums: {},
};

const applicationReducer = (state = initialState, action) => {
  switch (action.type) {
    case IS_EMBEDDED:
      return {
        ...state,
        isEmbedded: action.payload,
      }

    case JOURNEY_PROGRESS:
      return {
        ...state,
        journeyProgress: action.payload,
      }

    case SET_TRANSACTION_ID:
      return {
        ...state,
        transactionID: action.payload,
      }

    case SET_ENUMS:
      return {
        ...state,
        enums: action.payload,
      }

    default:
      return state;
  }
};

export default applicationReducer;
