import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Skeleton } from "@material-ui/lab";

import './index.css';

const CardProcessingOverlay = () => {
  return (
    <div>
      <div className="input-container">
        <div className="wpwl-label">Card Number</div>
        <Skeleton height="50px" />
      </div>

      <div className="input-container half-width mr-2">
        <div className="wpwl-label">Expiry Date</div>
        <Skeleton height="50px" />
      </div>

      <div className="input-container half-width">
        <div className="wpwl-label">CVC Number</div>
        <Skeleton height="50px" />
      </div>

      <div className="input-container">
        <div className="wpwl-label">Cardholder Name</div>
        <Skeleton height="50px" />
      </div>

      <div className="wpwl-group wpwl-group-submit wpwl-clearfix">
        <div className="wpwl-wrapper wpwl-wrapper-submit">
          <button className="wpwl-button wpwl-button-pay" type="submit" aria-label="Pay now" style={{ pointerEvents: 'none', background: 'rgb(103, 1, 153, 0.6)' }}>
            Processing Payment <FontAwesomeIcon style={{ marginLeft: '0.5em' }} icon={faSpinner} spin />
          </button>
        </div>
      </div>
    </div>

  )
}

export default CardProcessingOverlay;