import React from "react";
import Logo from "../Logo";

import './index.css';

const MaintenanceMode = () => {
  return (
    <div className="page-maintenance-wrapper">
      <div className="inner">
        <Logo width={120} />
        <h1 className="nk-error-title">Payment not possible at this time</h1>
        <h2>
          We are currently undertaking scheduled maintenance to our systems to provide customers with a better experience. Please check back in the next few hours.
        </h2>
        <br />
        <h2>Sorry for the incovenience</h2>
        <h2>The Felloh Team</h2>
      </div>
    </div>
  );
}

export default MaintenanceMode;