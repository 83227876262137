import React from 'react';

import masterCard from '../../assets/img/mastercard-card.png';
import visaCard from '../../assets/img/visa-card.png';
import genericCard from '../../assets/img/generic-card.png';

const switcher = ({ type }) => {
  if (type === 'VISA') {
    return (
      <img src={visaCard} alt="Visa" className="card__credit-card" />
    );
  }

  if (type === 'MASTER') {
    return (
      <img src={masterCard} alt="Mastercard" className="card__credit-card" />
    );
  }

  return (
    <img src={genericCard} alt="Credit Card" className="card__credit-card" />
  );
}

export default switcher;
