import React, { useEffect } from 'react';
import {Switch, Route, useHistory, useLocation} from 'react-router-dom';

// Pages
import _404 from './pages/errors/404';
import Link404 from './pages/errors/link404';
import Processing500 from './pages/errors/processing500';
import Transaction404 from './pages/errors/transaction404';
import Paid from './pages/errors/paid';
import Expired from './pages/errors/expired';

import Processing from './pages/processing';
import Success from './pages/success';
import Payment from './pages/payment';
import Footer from './components/Footer';
import LogRocket from 'logrocket';
import {useSelector} from "react-redux";
import HeightPusher from "./components/HeightPusher";
import ConfirmicWrapper from "./components/ConfirmicWrapper";
import posthog from 'posthog-js'
import MaintenanaceMode from './components/MaintenanceMode';

export default function App() {
  const { application } = useSelector((state) => state);
  const location = useLocation();
  const history = useHistory();

  React.useEffect(() => {
    if (posthog.__loaded) {
      posthog.capture('$pageview')
    }
  }, [location]);

  useEffect(() => {
    if (application.isEmbedded === true) {
      const formHeight = document.getElementById('root') !== null
        ? document.getElementById('root').clientHeight
        : '1000';

      window.parent.postMessage(JSON.stringify({
        iframe_height: formHeight < 420 ? 420 : formHeight,
      }));
    }
  }, [location, application.isEmbedded]);

  useEffect(() => {
    if (isLogRocketEnabled()) {
      LogRocket.init(process.env.REACT_APP_LOGROCKET_ID);
    }
  }, []);

  const isLogRocketEnabled = () => {
    return process.env.REACT_APP_ENABLE_LOGROCKET === "true";
  };


  if (process.env.REACT_APP_MAINTENANCE_MODE === 'active') {
    return (
      <React.Fragment>
        <MaintenanaceMode />
      </React.Fragment>
    );
  }

  return (
    <ConfirmicWrapper>
        <div className="App">
          <HeightPusher>
            <main style={{ background: history.location.pathname.includes('success') ? '#670199' : '#fff' }}>
              <Switch>
                <Route exact path="/blank" render={
                  (props) => <div style={{ position: 'absolute', left: 0, top: 0, height: '100vh', width: '100vw', background: '#f4f8fa' }}/>}
                />
                <Route exact path="/error/link/not-found" component={Link404} />
                <Route exact path="/error/transaction/not-found" component={Transaction404} />
                <Route exact path="/error/processing" component={Processing500} />
                <Route exact path="/error/link/paid" component={Paid} />
                <Route exact path="/error/link/expired" component={Expired} />
                <Route exact path="/:transactionId" component={Payment} />
                <Route exact path="/:transactionId/success/" component={Success} />
                <Route exact path="/:transactionId/processing/" component={Processing} />

                <Route exact path="/embed/:transactionId" render={
                  (props) => <Payment {...props} isEmbedded={true} />}
                />
                <Route exact path="/embed/:transactionId/processing" render={
                  (props) => <Processing {...props} isEmbedded={true} />}
                />
                <Route component={_404} />
              </Switch>
            </main>

            { application.isEmbedded !== true && location.pathname !== '/blank' && (
              <Footer />
            )}
          </HeightPusher>
        </div>
    </ConfirmicWrapper>
  );
}
