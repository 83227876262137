import React from 'react';
import {useSelector} from "react-redux";
import {ConfirmicProvider, ConsentGate} from '@confirmic/react';

const ConfirmicWrapper = (props) => {
  const { isEmbedded } = useSelector((state) => state.application);

  if (isEmbedded === true || typeof process.env.REACT_APP_CONFIRMIC_COOKIE_PROJECT_ID === 'undefined') {
    return <React.Fragment>
      { props.children }
    </React.Fragment>
  }

  return (
    <ConfirmicProvider projectId={process.env.REACT_APP_CONFIRMIC_COOKIE_PROJECT_ID}>
      { props.children }
      <ConsentGate micropolicy="Statistics Essential" />
    </ConfirmicProvider>
  )
}

export default ConfirmicWrapper;
