import React from 'react';
import {useSelector} from "react-redux";

const TotalProcessingAPM = () => {
  const { payment: { paymentDetails } } = useSelector((state) => state);
  const { totalProcessing, application: { isEmbedded }  } = useSelector((state) => state);

  // Check to see if Total Processing GPAY is available
  const isEnabled = paymentDetails?.providers_payment_types?.TOTAL_PROCESSING?.googlepay_enabled === true;

  if (!isEnabled || !totalProcessing.transactionID || totalProcessing.loaded === false) return <React.Fragment />;

  let processingURL = window.location.protocol + '//' + window.location.host + '/' + totalProcessing.transactionID + '/processing';

  if (isEmbedded === true) {
    processingURL = window.location.protocol + '//' + window.location.host + '/embed/' + totalProcessing.transactionID + '/processing';
  }

  return (
    <React.Fragment>
      <form action={ processingURL } className="paymentWidgets" data-brands="GOOGLEPAY" />
    </React.Fragment>
  )
}

export default TotalProcessingAPM;
