import React, {  useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import * as Sentry from '@sentry/browser';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

import './index.css';

import { transformCurrency } from '../../../../lib/currency';
import { paymentRedirect } from '../../../../utils';
import { getSafeChargeEnv } from '../utils';

const INIT_COUNTRY = 'GB';

export const NuveiApplepay = ({ safeCharge, paymentDetails, sessionToken, merchantSiteID, currency, transactionID, applePayMerchantName}) => {
  const { application: { isEmbedded }  } = useSelector((state) => state);

  const [supportApplePay, setSupportApplePay] = useState(null);
  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    // Verify that you have an Apple Pay device
    if (window.ApplePaySession) {
      try {
        const canMakePaymentsResult = window.ApplePaySession.canMakePayments();
        setSupportApplePay(canMakePaymentsResult);
      } catch (err) {
        Sentry.captureException(err);
      }
    }
  }, []);

  const getApplePayRequest = () => ({
    env: getSafeChargeEnv(), // Required again for applePay init
    merchantSiteId: merchantSiteID, // Required again for applePay processing
    countryCode: INIT_COUNTRY,
    currencyCode: transformCurrency(currency, paymentDetails.currency),
    total: {
      label: applePayMerchantName,
      amount:  (paymentDetails.amount / 100).toString(),
    },
    requiredBillingContactFields: ['postalAddress'],
    requiredShippingContactFields: ['email'], // Can't request "email" from billingContact, only shippingContact
  });

  const triggerPayment = () => {
    const applePayRequest = getApplePayRequest();

    try {
      safeCharge.applePay.buildSession(
        applePayRequest,
        (providerResponse, providerCallback) => createPayment(providerResponse, providerCallback),
      ).begin();
    } catch (err) {
      Sentry.captureException(err);
    }
  }

  const createPayment = (providerResponse, providerCallback) => {
    if (!providerResponse) {
      return;
    }

    setProcessing(true);

    const { token, billingContact, shippingContact } = providerResponse;

    const paymentToken = JSON.stringify(token);

    const data = {
        sessionToken: sessionToken,
        paymentOption: {
          card: {
            externalToken: {
              externalTokenProvider: 'ApplePay',
              mobileToken: paymentToken,
            }
          }
        },
        billingAddress: {
          email: shippingContact?.emailAddress,
          country: billingContact?.countryCode,
          address: (billingContact?.addressLines?.length > 0) ? billingContact.addressLines[0] : null,
          city: billingContact?.locality,
          zip: billingContact?.postalCode,
        },
    };

    try {
      safeCharge.createPayment(
        data,
        (response) => {
          if (response.result === 'APPROVED') {
            if (providerCallback) providerCallback(true); // Needed to show feedback on the payment gate on the iphone
            setProcessing(false);
            paymentRedirect(true, transactionID, isEmbedded);
          } else {
            if (providerCallback) providerCallback(false); // Needed to show feedback on the payment gate on the iphone
            setProcessing(false);
            // TODO: Handle errors usefully. Redirect to error page?
          }
        }
      );
    } catch (err) {
      Sentry.captureException(err);
      if (providerCallback) providerCallback(false);
      setProcessing(false);
      paymentRedirect(false, transactionID, isEmbedded);
    }
  };

  return supportApplePay && (
    <div style={{ marginTop: '1em'}}>
      {!processing &&
        <div className="apple-pay-button apple-pay-button-black"
          onClick={() => triggerPayment()}
          style={{ width: '100%' }}
        />}
      {processing &&
        <div className="pullLeft" style={{ width: '100%' }}>
          <div className="card_frame__loading">
            <FontAwesomeIcon icon={faSpinner} spin />
          </div>
        </div>
      }
    </div>
  )

};
