import React from "react";
import { ReactComponent as Logo } from '../../assets/img/500.svg';

export default function Processing500() {
  return (
    <div className="page-404__wrapper">
      <Logo />
      <h1 className="nk-error-title">An error has occurred</h1>
      <h2 className="page-404__subtitle">
        An error has occurred while processing your payment, we will investigate the issue and get in contact with you by email to resolve.
      </h2>
    </div>
  );
}



