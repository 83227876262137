import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

import API_ROUTES from '../../../config/api';
import { setJourneyProgress } from '../../../action/application';
import { RENDERED } from '../../../constants/journey-progress';
import CardLogo from '../../../components/CardLogo';
import masterCard from '../../../assets/img/mc_hrz_pos.svg';
import visaCard from '../../../assets/img/visa.svg';

import { NuveiCard } from './card';
import { NuveiGooglepay } from './googlepay';
import { NuveiApplepay } from './applepay';
import { getSafeChargeEnv, getGpayEnv } from './utils';

const loadScript = (src) =>
  new Promise((resolve, reject) => {
    const scriptElem = Object.assign(document.createElement('script'), {
      type: 'text/javascript',
      defer: true,
      src,
      onerror: (e) => {
        reject(e);
      }
    });
    scriptElem.onload = () => {
      resolve();
    };
    document.body.appendChild(scriptElem);
  });

export default function Nuvei({ transactionMethod }) {
  const { payment: { paymentDetails }, nuvei, application: { isEmbedded } } = useSelector((state) => state);
  const { currency } = useSelector((state) => state.application.enums);
  const [safeCharge, setSafeCharge] = useState(null);
  const [gpayApi, setGpayApi] = useState(null);
  const [countries, setCountries] = useState();
  const dispatch = useDispatch();
  const googlepayEnabled = paymentDetails?.providers_payment_types?.NUVEI?.googlepay_enabled;
  const applepayEnabled = paymentDetails?.providers_payment_types?.NUVEI?.applepay_enabled;

  useEffect(() => {
    if (
      !nuvei.transactionID
      || !nuvei.sessionToken
      || !nuvei.merchantID
      || !nuvei.merchantSiteID
      || nuvei.loaded === false
    ) {
      return;
    }

    /**
     * Nuvei (Safecharge) WebSDK functions
     *
     * accountCapture(e,t)
     * authenticate3d(o,r)
     * buildApplePaySession(e,t,n)
     * clientGetDccDetails(e,t)
     * createApplePayPayment(n,a)
     * createApplePayPaymentWithoutBuildSession(t,n)
     * createGlobalpayPayment(e,t)
     * createPayment(h,f)
     * createSightlinePayment(e,t)
     * enrollAccount(e,t)
     * enrollSightlineAccountWithFund(e,t)
     * fields(e)
     * getApms(e,t)
     * getCardDetails(e,t)
     * getDirectUPOs()
     * getToken(e,t)
     * getUserUPOs(e)
     * initGlobalpay(e,t,n,a)
     * initPayment(n,a)
     * initSightline(e,t,n,a)
     * paymentFrame(n,a)
     * setOpenAmount(e,t)
     */
    loadScript('https://cdn.safecharge.com/safecharge_resources/v1/websdk/safecharge.js')
    .then(async () => {
      // Use window.sfc to follow safeCharge/Nuvei convention (required when also adding applePay script)
      window.sfc = window.SafeCharge({
        env: getSafeChargeEnv(),
        merchantId: nuvei.merchantID,
        merchantSiteId: nuvei.merchantSiteID,
      });

      if (applepayEnabled) {
        // Adds "applePay" object to window.sfc (i.e. window.sfc.applePay now exists)
        await loadScript('https://cdn.safecharge.com/safecharge_resources/v1/sc_api_applepay.min.js');
      }

      setSafeCharge(window.sfc);

      dispatch(setJourneyProgress(RENDERED));
    });

    if (googlepayEnabled) {
      loadScript('https://pay.google.com/gp/p/js/pay.js')
      .then(() => {
        const gpayApi = new window.google.payments.api.PaymentsClient({
          environment: getGpayEnv(),
        });
        setGpayApi(gpayApi);
      });
    }
  }, [nuvei]);

  useEffect(() => {
    async function fetchCountries() {
      const { data } = await axios.get(API_ROUTES.paymentLink['countries:list']());
      setCountries(data.data);
    };
    fetchCountries();
  }, []);

  if (!nuvei.transactionID || !nuvei.sessionToken || nuvei.loaded === false || !safeCharge || !countries) {
    return (
      <div className="card_frame__loading">
        <h2>Initiating Card Payment</h2>
        <FontAwesomeIcon icon={faSpinner} spin />
      </div>
    )
  }

  return (
    <div id="provider_nuvei">
      {googlepayEnabled &&
      <NuveiGooglepay
        safeCharge={safeCharge}
        gpayApi={gpayApi}
        paymentDetails={paymentDetails}
        sessionToken={nuvei.sessionToken}
        transactionID={nuvei.transactionID}
        currency={currency}
        googlepayMerchantId={nuvei.googlepayMerchantId}
        googlepayMerchantName={nuvei.googlepayMerchantName}
        googlepayAllowedCardNetworks={nuvei.googlepayAllowedCardNetworks}
      />}
      {applepayEnabled &&
        <NuveiApplepay
          safeCharge={safeCharge}
          paymentDetails={paymentDetails}
          sessionToken={nuvei.sessionToken}
          merchantSiteID={nuvei.merchantSiteID}
          transactionID={nuvei.transactionID}
          currency={currency}
          applePayMerchantName={nuvei.applePayMerchantName}
        />
      }
      { isEmbedded !== true &&
        <h3 className="clear">Pay by Credit/Debit Card</h3>
      }
      <NuveiCard
        safeCharge={safeCharge}
        sessionToken={nuvei.sessionToken}
        transactionID={nuvei.transactionID}
        transactionMethod={transactionMethod}
        countries={countries}
      />
      <div className="app_logo" style={{marginTop:"30px"}}>
        <CardLogo width="20%" src={masterCard} padding="6px" />
        <CardLogo width="12%" src={visaCard} />
      </div>
    </div>
  );
};
