export const LOAD = '@application/load';
export const IS_EMBEDDED = '@application/embedded';
export const JOURNEY_PROGRESS = '@application/journey-progress';
export const SET_ENUMS = '@application/set-enums';
export const SET_TRANSACTION_ID = '@application/set-transaction-id';

export function triggerLoad() {
  return (dispatch) => dispatch({
    type: LOAD,
    payload: {}
  });
}

export const setEmbedded = (isEmbedded) => {
  return {
    type: IS_EMBEDDED,
    payload: isEmbedded,
  };
};

export const setJourneyProgress = (progress) => {
  return {
    type: JOURNEY_PROGRESS,
    payload: progress,
  };
};

export const setEnums = (enums) => {
  return {
    type: SET_ENUMS,
    payload: enums,
  };
};

export const setTransactionID = (transactionID) => {
  return {
    type: SET_TRANSACTION_ID,
    payload: transactionID,
  };
}
