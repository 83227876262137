export const UPDATE_SURCHARGE_RULE = '@payment/update-surcharge-rule';
export const UPDATE_PAYMENT_METHOD = '@payment/update-payment-method';
export const UPDATE_PAYMENT_DETAILS = '@payment/update-payment-details';
export const RESET_STATE = '@payment/rest-state';

export const updateSurchargeRule = (surchargeRule) => {
  return {
    type: UPDATE_SURCHARGE_RULE,
    payload: surchargeRule,
  };
}

export const updatePaymentMethod = (paymentMethod) => {
  return {
    type: UPDATE_PAYMENT_METHOD,
    payload: paymentMethod,
  };
}

export const updatePaymentDetails = (paymentDetails) => {
  return {
    type: UPDATE_PAYMENT_DETAILS,
    payload: paymentDetails,
  };
};

export const resetState = () => {
  return {
    type: RESET_STATE,
  };
};
