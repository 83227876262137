import React from "react";

export default function ListOfBanks({ bankList, onBankClick }) {
  return (
    <div>
      {bankList.map((bank) => (
        <div className="bank__button_container" key={bank.id}>
          <button className="bank__button" id={bank.name.split(' ').join('_').toLowerCase()} onClick={() => onBankClick(bank)}>
            <span className="bank__button__content">
              <span>{bank.name}</span>
              {/* TODO: get transparent SVG's for all banks */}
              <img src={bank.logo} alt={bank.name} />
            </span>
          </button>
        </div>
      ))}
    </div>
  );
}
