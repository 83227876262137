import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';
import { CARD, OPEN_BANKING } from '../../../../constants/payment-method';
import { updatePaymentMethod } from "../../../../action/payment";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCreditCard, faBuildingColumns } from '@fortawesome/free-solid-svg-icons'
import TotalProcessingAPM from './apm/total-processing';
import {Skeleton} from "@material-ui/lab";

const countPaymentMethods = (paymentDetails, nuapay) => {
  const gPayEnabled = paymentDetails?.providers_payment_types?.TOTAL_PROCESSING?.googlepay_enabled === true;

  let count = paymentDetails.payment_methods.length;

  if (gPayEnabled === true && (!paymentDetails.tokenisationProviders || paymentDetails.tokenisationProviders.length === 0)) {
    count++;
  }

  if (nuapay.unavailable === true) {
    count = count - 1;
  }

  return count;
};

const MethodSelector = ({ initiating }) => {
  const { payment: { paymentDetails, paymentMethod }, application: { isEmbedded }, nuapay } = useSelector((state) => state);

  const dispatch = useDispatch();

  if (isEmbedded === true) return <React.Fragment />;

  // If the payment link is not loaded then do not show
  if (!paymentDetails.id || initiating === true) {
    return (
      <React.Fragment>
        <div className="payment-type-selectors">
          <a className="payment-type-selector skeleton">
            <Skeleton />
          </a>

          <a className="payment-type-selector skeleton">
            <Skeleton />
          </a>

        </div>
      </React.Fragment>
    )
  }

  // If only one payment method is available, set as the selected payment method.
  if (paymentDetails.payment_methods.length === 1 && paymentMethod !== paymentDetails.payment_methods[0]) {
    dispatch(updatePaymentMethod(paymentDetails.payment_methods[0]));
  }

  // If only one payment method is available then do not show
  if (countPaymentMethods(paymentDetails, nuapay) <= 1) return <React.Fragment />;

  return (
    <React.Fragment>
      <div className="payment-type-selectors">
        {paymentDetails.payment_methods.includes(CARD) && (
          <a
            onClick={() => dispatch(updatePaymentMethod(CARD))}
            className={classnames(
              "payment-type-selector",
              {
                "payment-type-selector--active": paymentMethod === CARD,
              },
            )}>
                  <span className="payment-type-selector__icon">
                    <FontAwesomeIcon icon={faCreditCard} />
                  </span>
            <span className="payment-type-selector__label">Card</span>
          </a>
        )}

        {paymentDetails.payment_methods.includes(OPEN_BANKING) && nuapay.unavailable === false && (
          <a
            onClick={() => dispatch(updatePaymentMethod(OPEN_BANKING))}
            className={classnames(
              "payment-type-selector",
              {
                "payment-type-selector--active": paymentMethod === OPEN_BANKING,
              },
            )}>
                  <span className="payment-type-selector__icon">
                    <FontAwesomeIcon icon={faBuildingColumns} />
                  </span>
            <span className="payment-type-selector__label">Open Banking</span>
          </a>
        )}

        <TotalProcessingAPM />

      </div>
    </React.Fragment>
  )
}

export default MethodSelector;
