import React, { useEffect, useRef } from 'react';
import {useSelector} from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as Sentry from "@sentry/browser";

import './style.css'

const DSIframe = ({ payment, setIframeRendered, paymentDetails }) => {
  const { application: { isEmbedded }  } = useSelector((state) => state);
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [hasFailed, setHasFailed] = React.useState(false);
  const {hasFailedCount, setHasFailedCount} = React.useState(0);

  const history = useHistory();

  const formRef = useRef(null);
  const iframeRef = useRef(null);

  Sentry.setContext("payment", {
    paymetLinkID: paymentDetails?.id,
    url: payment?.redirect?.url,
  });

  useEffect(() => {
    if (payment?.redirect?.method === 'GET') {
      iframeRef.current.src = payment?.redirect?.url;
      iframeRef.current.style.background = '#fff';
    } else {
      formRef.current.submit();
    }

    return reRenderTimeout();
  }, [payment]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (isLoaded === false) {
        Sentry.captureException('3DS iframe failed to reach load');
      }

      clearTimeout(timeoutId);
    }, 20000);

    return () => clearTimeout(timeoutId);
  }, []);

  const reRenderTimeout = () => {
    const timeoutId = setTimeout(() => {
      if (isLoaded) {
        // setHasFailed(true);

        if (hasFailedCount === 0) {
          Sentry.captureException('3DS iframe failed to load', {
            tags: {
              paymetLinkID: paymentDetails?.id,
            },
          });
        }
        setHasFailedCount(hasFailedCount + 1);
      }
    }, 10000);

    return () => clearTimeout(timeoutId);
  }

  const loadEvent = (e) => {
    let url = null;

    setIsLoaded(true);
    setIframeRendered(true);

    try {
      url = e.target.contentWindow.location.href;
    } catch(err) {}

   if (url) {
    if (isEmbedded === true) {
      history.push(`/embed/${payment.id}/processing`)
    } else {
      history.push(`/${payment.id}/processing`)
    }
   }
  }

  const triggerReRender = () => {
    console.log('trigger re-render');
    setIsLoaded(false);
    setHasFailed(false);
    formRef.current.submit();

    return reRenderTimeout();
  }

  if (hasFailed === true) {
    return (
      <React.Fragment>
        {triggerReRender()}
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <form ref={formRef} action={payment?.redirect?.url} method='POST' target="3ds_iframe">
        { (payment?.redirect?.parameters || []).map((parameter) => (
          <input type='hidden' name={parameter.name} key={parameter.name} value={parameter.value} />
        ))}
      </form>
      <iframe
        ref={iframeRef}
        className="ds-iframe"
        title='3ds_iframe'
        name="3ds_iframe"
        onLoad={loadEvent}
        style={{ display: isLoaded === true ? 'block' : 'none' }} 
      />
    </React.Fragment>

  )
}

export default DSIframe;