/**
 * Save State to session storage
 * @param state
 */
export const saveState = (state) => {
  try {
    const serializedState = JSON.stringify({
      ...state,
      totalProcessing: {
        ...state.totalProcessing,
        loaded: false,
      }
    });
    sessionStorage.setItem('state', serializedState);
  } catch (err) {
    // ignore write errors
  }
};

/**
 * Load state from session storage
 * @return {*}
 */
export const loadState = () => {
  try {
    const serializedState = sessionStorage.getItem('state');
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

