export const UPDATE_BANKS = '@provider/nuapay/update-banks';
export const BANKING_UNAVAILABLE = '@provider/nuapay/banking-unavailable';

export const updateBanks = (banks) => {
  return {
    type: UPDATE_BANKS,
    payload: banks,
  };
}

export const bankingUnavailable = (payload) => {
  return {
    type: BANKING_UNAVAILABLE,
    payload: payload,
  };
}
