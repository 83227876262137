import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useResizeDetector } from 'react-resize-detector/build/withPolyfill';
import {useSelector} from "react-redux";

const HeightPusher = (props) => {
  const { isEmbedded } = useSelector((state) => state.application);
  const { height, ref } = useResizeDetector();

  useEffect(() => {
    if (isEmbedded === true) {
      window.parent.postMessage(JSON.stringify({
        iframe_height: height < 420 ? 420 : height,
      }), '*');
    }
  }, [ref, height]);

  if (isEmbedded !== true) {
    return (
      <React.Fragment>
        { props.children }
      </React.Fragment>
    );
  }

  return (
    <div id="pusher-area" ref={ref}>
      { props.children }
    </div>
  );
};

export default withRouter(HeightPusher);
