import { takeLatest, select, put } from 'redux-saga/effects';
import axios from "axios";
import * as Sentry from "@sentry/browser";

import API_ROUTES from "../../config/api";
import { getPayment, getApplication } from "../selectors";
import { UPDATE_PAYMENT_DETAILS } from "../../action/payment";
import { UPDATE_BANKS, BANKING_UNAVAILABLE } from "../../action/provider/nuapay";
import {UPDATE_PAYMENT_CONFIGURATION, RELOAD} from "../../action/provider/total-processing";
import { NUVEI_UPDATE_CONFIG, NUVEI_SET_LOADED } from "../../action/provider/nuvei";
import { SET_LOADING as BT_SET_LOADING } from '../../action/provider/basis-theory'
import getBrowserFingerprint from "get-browser-fingerprint";

function* fetchNuaPayBanks() {
  yield put({ type: UPDATE_BANKS, payload: []});

  let { isEmbedded } = yield select(getApplication);
  let { paymentDetails } = yield select(getPayment);

  if (typeof paymentDetails.providers === 'undefined' ||
    paymentDetails.providers.length === 0 ||
    paymentDetails.providers.indexOf('NUAPAY') === -1
  ) {
    return null;
  }

  try {
    const { data } = yield axios.get(API_ROUTES.nuapay["banks:get"](
      paymentDetails.id,
      isEmbedded === true ? 'ecommerce' : 'payment-link',
    ));

    const banks = data.data.sort(( a, b ) => {
      if ( a.name < b.name ){
        return -1;
      }
      if ( a.name > b.name ){
        return 1;
      }
      return 0;
    });

    yield put({ type: UPDATE_BANKS, payload: banks});
  } catch (err) {
    yield put({ type: BANKING_UNAVAILABLE, payload: true});
  }
}

function* initiateTotalProcessingTransaction() {
  let { paymentDetails } = yield select(getPayment);
  let { isEmbedded } = yield select(getApplication);

  if (typeof paymentDetails.providers === 'undefined' ||
    paymentDetails.providers.length === 0 ||
    paymentDetails.providers.indexOf('TOTAL_PROCESSING') === -1 ||
    paymentDetails?.tokenisationProviders?.length !== 0
  ) {
    return null;
  }

  // Fingerprint the browser
  let fingerprint;
  try {
    fingerprint = getBrowserFingerprint();
  } catch(error) {
    fingerprint = null;
  }

  try {
    const { data } = yield axios.post(API_ROUTES.totalProcessing["card-link:get"](
      paymentDetails.id,
      isEmbedded === true ? 'ecommerce' : 'payment-link',
      paymentDetails.transactionMethod,
    ), {
      identifier: fingerprint,
    });

    yield put({
      type: UPDATE_PAYMENT_CONFIGURATION,
      payload: {
        transactionID: data.data.transaction_id,
        checkoutID: data.data.checkout_id,
      }
    });
  } catch (err) {
    Sentry.captureException(err);
    window.location.href = '/error/processing';
  }
}

function* initiateNuveiTransaction() {
  yield put({ type: NUVEI_SET_LOADED, payload: false});

  let { paymentDetails } = yield select(getPayment);
  let { isEmbedded } = yield select(getApplication);

  if (typeof paymentDetails.providers === 'undefined' ||
    paymentDetails.providers.length === 0 ||
    paymentDetails.providers.indexOf('NUVEI') === -1
  ) {
    return null;
  }

  // Fingerprint the browser
  let fingerprint;
  try {
    fingerprint = getBrowserFingerprint();
  } catch(error) {
    fingerprint = null;
  }

  try {
    const { data } = yield axios.post(API_ROUTES.nuvei["card-link:get"](
      paymentDetails.id,
      isEmbedded === true ? 'ecommerce' : 'payment-link',
      paymentDetails.transactionMethod,
    ), {
      identifier: fingerprint,
    });

    yield put({
      type: NUVEI_UPDATE_CONFIG,
      payload: {
        sessionToken: data.data.session_token,
        merchantID: data.data.merchant_id,
        merchantSiteID: data.data.merchant_site_id,
        transactionID: data.data.transaction_id,
        googlepayMerchantId: data.data.googlepay_merchant_id,
        googlepayMerchantName: data.data.googlepay_merchant_name,
        googlepayAllowedCardNetworks: data.data.googlepay_allowed_card_networks,
        applePayMerchantName: data.data.applePay_merchant_name,
      }
    });
  } catch (err) {
    Sentry.captureException(err);
    window.location.href = '/error/processing';
  }
}

function* initiateBasisTheoryTransaction() {
  yield put({ type: BT_SET_LOADING, payload: false});
}

export default function* sagas() {
  // Basis Theory
  yield takeLatest(UPDATE_PAYMENT_DETAILS, initiateBasisTheoryTransaction);

  // Nuapay
  yield takeLatest(UPDATE_PAYMENT_DETAILS, fetchNuaPayBanks);

  // Total Processing
  yield takeLatest(UPDATE_PAYMENT_DETAILS, initiateTotalProcessingTransaction);
  yield takeLatest(RELOAD, initiateTotalProcessingTransaction);

  // Nuvei
  yield takeLatest(UPDATE_PAYMENT_DETAILS, initiateNuveiTransaction);
}
