import React, { useEffect } from "react";
import { Backdrop, Box, CircularProgress, makeStyles, Typography } from "@material-ui/core";
import zIndex from "@material-ui/core/styles/zIndex";
import {useSelector} from "react-redux";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: zIndex.drawer + 1,
    color: '#fff',
    display: "flex",
  },
  progress: {
    marginTop: '1em',
  }
}));

export default function FullScreenLoader({ children, open, white }) {
  const { isEmbedded } = useSelector((state) => state.application);

  useEffect(() => {
    try {
      if (open === true) {
        document.querySelector('main').style.maxHeight = '100vh';
      } else {
        document.querySelector('main').style.maxHeight = '';
      }
    } catch (e) {
      console.log('resize not supported')
    }

    return () => {
      try {
        document.querySelector('main').style.maxHeight = '';
      } catch (e) {
        console.log('resize not supported')
      }
    };
  }, [open]);

  const classes = useStyles();
  return (
    <Backdrop
      className={classes.backdrop}
      style={{ backgroundColor: isEmbedded === true ? '#fff' : '#670199' }}
      transitionDuration={{
        appear: 0,
        enter: 0,
        exit: 400,
      }}
      open={open}>
      <Box textAlign="center">
        <Typography component="p" variant={ isEmbedded === true ? "h6" : "h4" } gutterBottom style={{ color: isEmbedded === true ? '#000' : '#fff' }}>
          {children}
        </Typography>
        <CircularProgress style={{ color: isEmbedded === true ? '#670199' : '#fff' }} className={classes.progress} />
      </Box>
    </Backdrop>
  );
}
