/* eslint no-undef: 0 */

import React, { useEffect, useRef, useState } from 'react';
import DSIframe from './3ds-iframe';
import {
  CardExpirationDateElement,
  CardNumberElement,
  CardVerificationCodeElement,
  useBasisTheory,
  BasisTheoryProvider
} from "@basis-theory/basis-theory-react";
import { useDispatch, useSelector } from "react-redux";
import CardLoading from "../../../components/CardLoading";
import API_ROUTES from "../../../config/api";
import { setJourneyProgress } from '../../../action/application';
import { RENDERED } from '../../../constants/journey-progress';
import axios from 'axios';
import * as Sentry from '@sentry/browser';
import { updateSurchargeRule } from "../../../action/payment";
import { isMoto as MOTOCheck } from "../../../constants/transaction-method";
import CardProcessingOverlay from './card-processing-overlay';
import getBrowserFingerprint from "get-browser-fingerprint";
import { useHistory } from 'react-router-dom';
import CountryCodes from '../../../constants/country-codes';
import { formatCurrency } from '../../../lib/currency';

const convertBrand = (brand) => {
  switch (brand) {
    case 'visa':
      return 'VISA';
    case 'mastercard':
      return 'MASTER';
    case 'american-express':
      return 'AMEX';
    default:
      return brand;
  }
};

const CardForm = ({ bt, transactionMethod }) => {
  const cardElementRef = useRef(null);
  const expiryElementRef = useRef(null);
  const cvcElementRef = useRef(null);
  const history = useHistory();
  const dispatch = useDispatch();
  
  const {
    basisTheory,
    payment: { paymentDetails, surchargeRule },
    application: { isEmbedded, enums: { currency } } ,
  } = useSelector((state) => state);

  const [address, setAddress] = useState({
    country: paymentDetails?.customer?.country || 'GB',
    line1: paymentDetails?.customer?.address_1 || '',
    postcode: paymentDetails?.customer?.post_code || '',
  });

  const [name, setName] = useState('');
  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});
  const [bin, setBin] = useState({});
  const [blockRules, setBlockRules] = useState({});
  const [storeFuturePayments, setStoreFuturePayments] = useState(false);

  const [payment, setPayment] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [iframeRendered, setIframeRendered] = useState(false);

  const isMoto = MOTOCheck(transactionMethod);

  useEffect(() => {
    validate();
  }, [address, name]);

  useEffect(() => {
    dispatch(updateSurchargeRule({}));
    setBin({});
  }, []);

  useEffect(() => {
    validate();
  }, [bin]);

  const validate = (binData, localBlockRules) => {
    setErrors({});

    const card = cardElementRef.current;
    const cardExpirationDate = expiryElementRef.current;
    const cardVerificationCode = cvcElementRef.current;

    const errors = {};
    const binCheck = binData || bin?.rule;
    const blockCheck = localBlockRules || blockRules;

    // Card Number Checks
    if (blockCheck?.blocked === true) errors.card = { type: 'error', message: blockCheck?.message };
    if (card?.metadata?.valid === false) errors.card = { type: 'error', message: 'Card number is invalid' };
    if (card?.metadata?.empty === true) errors.card = { type: 'error', message: 'Card number is required' };
    if (binCheck?.message) {
      errors.card = { type: 'warning', message: binCheck?.message };
      setTouched({ ...touched, card: true });
    };

    // Card Expiry Checks
    if (cardExpirationDate?.metadata?.valid === false) errors.expiry = { type: 'error', message: 'Card expiration is not valid' };
    if (cardExpirationDate?.metadata?.empty === true) errors.expiry = { type: 'error', message: 'Card expiration is required' };

    // Card CVC Checks
    if (cardVerificationCode?.metadata?.valid === false) errors.cvc = { type: 'error', message: 'Card CVC is not valid' };
    if (cardVerificationCode?.metadata?.empty === true) errors.cvc = { type: 'error', message: 'Card CVC is required' };

    // Card Holder Checks
    if (name?.length <= 3) errors.holder = { type: 'error', message: 'Card holder name is not valid' };
    if (name?.length === 0) errors.holder = { type: 'error', message: 'Card holder name is required' };

    if (isMoto === true) {
      if (address.country?.length === 0) errors.country = { type: 'error', message: 'Country is required' };
      if (address.line1?.length === 0) errors.line1 = { type: 'error', message: 'Billing address line 1 is required' };
      if (address.postcode?.length === 0) errors.postcode = { type: 'error', message: 'Billing postcode is required' };
    }

    setErrors(errors);

    let errorCount = 0;

    for (const error in errors) {
      const item = errors[error];

      if (item.type === 'error') {
        errorCount += 1;
      }
    }

    return errorCount === 0;
  }

  const submit = async (event) => {
    try {
      event.preventDefault();

      let touchedItems = {
        card: true,
        expiry: true,
        cvc: true,
        holder: true,
      };

      if (isMoto) {
        touchedItems = {
          ...touchedItems,
          country: true,
          line1: true,
          postcode: true,
        };
      }

      setTouched(touchedItems);

      const card = cardElementRef.current;
      const cardExpirationDate = expiryElementRef.current;
      const cardVerificationCode = cvcElementRef.current;

      const validity = validate();

      if (validity === false) {
        return null;
      }

      setSubmitting(true);

      const metadata = {
        name: name.value,
      };
      address.country && (metadata.country = address.country);
      address.line1 && (metadata.line1 = address.line1);
      address.postcode && (metadata.postcode = address.postcode);

      const token = await bt.tokens.create({
        type: "card",
        data: {
          number: card,
          expiration_month: cardExpirationDate.month(),
          expiration_year: cardExpirationDate.year(),
          cvc: cardVerificationCode,
          name: name,
        },
        metadata,
      });

      // Fingerprint the browser
      let fingerprint;
      try {
        fingerprint = getBrowserFingerprint();
      } catch (error) {
        fingerprint = null;
      }

      let surchargeAmount = null;

      if (surchargeRule?.modifierType) {
        surchargeAmount = Math.round(surchargeRule?.modifierType === 'percentage' ? paymentDetails.amount * (surchargeRule.percentage / 100) : surchargeRule.amount);
      }

      const { data } = await axios.post(API_ROUTES.basisTheory["card-link:get"](
        paymentDetails.id,
        isEmbedded === true ? 'ecommerce' : 'payment-link',
        paymentDetails.transactionMethod,
      ), {
        token: token.id,
        name,
        store_token: storeFuturePayments,
        bin: card?._cardMetadata?.cardBin,
        card_brand: convertBrand(card?._cardMetadata?.cardBrand),
        identifier: fingerprint,
        surcharge: surchargeAmount,
        ...address,
      });

      setPayment(data.data);

      if (data?.data?.status !== 'PENDING' && !data?.data?.redirect?.url) {
        if (isEmbedded === true) {
          history.push(`/embed/${data?.data?.id}/processing`);
        } else {
          history.push(`/${data?.data?.id}/processing`);
        }
      } else {
        setSubmitting(false);
      }
    } catch (error) {
      Sentry.captureException(error);

      if (isEmbedded === true) {
        window.location.href = `/embed/${paymentDetails.id}?error=misc-error`;
      } else {
        window.location.href = `/${paymentDetails.id}?error=misc-error`;
      }
    }
  };

  const updateAddress = (event) => {
    setAddress({
      ...address,
      [event.target.name]: event.target.value,
    });
    setTouched({
      ...touched,
      [event.target.name]: true,
    });
  };

  const updateName = (event) => {
    setName(event.target.value);
    setTouched({
      ...touched,
      holder: true,
    });
  };

  const btInputBlur = async (item) => {
    setTouched({
      ...touched,
      [item.targetId]: true,
    });

    validate();
  }

  const cardTouch = async (event) => {
    if (event.valid === false) {
      dispatch(updateSurchargeRule({}));
      setBin({});
      validate();
    } else if (event.valid === true && event.complete === true && bin?.bin?.number !== event.cardBin) {
      setBin({})
      const card = cardElementRef.current;

      try {
        const binLookup = await axios.post(
          API_ROUTES.basisTheory["bin:lookup"](),
          {
            initiation_id: paymentDetails?.id,
            bin: card._cardMetadata.cardBin,
            organisation: paymentDetails?.organisation?.id,
          },
        );

        dispatch(updateSurchargeRule(binLookup.data.data.rule));
        setBin(binLookup.data.data);
        setBlockRules(binLookup?.data?.data?.block_rules);
        validate(binLookup?.data?.data?.rule, binLookup?.data?.data?.block_rules);
      } catch (error) {
        Sentry.captureException(error);
      }
    }
  };

  if (basisTheory.loading === true) {
    return (
      <CardLoading />
    )
  }

  const formReady = () => {
    dispatch(setJourneyProgress(RENDERED));
  }

  return (
    <div className="payment-form">

      {payment?.redirect?.url && (
        <DSIframe payment={payment} setIframeRendered={setIframeRendered} paymentDetails={paymentDetails} />
      )}

      {(submitting === true || (payment?.redirect?.url && iframeRendered === false)) && (
        <CardProcessingOverlay />
      )}

      {!payment?.redirect?.url && (
        <React.Fragment>

          <form onSubmit={submit} style={{ display: submitting === true ? 'none' : 'block' }}>

            <div className="input-container">
              <div className="wpwl-label wpwl-label-cardNumber">Card Number</div>
              <div className={`input-field${errors.card && touched.card ? ` input-field-${errors.card.type}` : ''}`}>
                <CardNumberElement id="card" ref={cardElementRef} onBlur={btInputBlur} onChange={cardTouch} onReady={formReady} />
              </div>
              {errors.card && touched.card && <div className={errors.card.type}>{errors.card.message}</div>}
            </div>

            <div className="input-container half-width mr-2">
              <div className="wpwl-label wpwl-label-cardNumber">Expiry Date</div>
              <div className={`input-field${errors.expiry && touched.expiry ? ' input-field-error' : ''}`}>
                <CardExpirationDateElement className="input-field" id="expiry" ref={expiryElementRef} onBlur={btInputBlur} />
              </div>
              {errors.expiry && touched.expiry && <div className={errors.expiry.type}>{errors.expiry.message}</div>}

            </div>

            <div className="input-container half-width">
              <div className="wpwl-label wpwl-label-cardNumber">CVC Number</div>
              <div className={`input-field${errors.cvc && touched.cvc ? ' input-field-error' : ''}`}>
                <CardVerificationCodeElement className="input-field" id="cvc" ref={cvcElementRef} onBlur={btInputBlur} />
              </div>
              {errors.cvc && touched.cvc && <div className={errors.cvc.type}>{errors.cvc.message}</div>}
            </div>

            <div className="input-container clear">
              <div className="wpwl-label wpwl-label-cardNumber">Cardholder Name</div>
              <div className={`input-field${errors.holder && touched.holder ? ' input-field-error' : ''}`}>
                <input name="holder" placeholder="Cardholder Name" onChange={updateName} value={name} />
              </div>
              {errors.holder && touched.holder && <div className={errors.holder.type}>{errors.holder.message}</div>}
            </div>

            {isMoto && (
              <div className="input-container">

                <div className="wpwl-label wpwl-label-cardNumber">Billing Address</div>

                <div className="mb-1 clear">
                  <div className={`input-field${errors.country && touched.country ? ' input-field-error' : ''}`}>
                    <select name="country" placeholder="Country" value={address.country} onChange={updateAddress}>
                      {CountryCodes.map((country) => (
                        <option key={country.code} value={country.code}>{country.name}</option>
                      ))}
                    </select>
                  </div>
                  {errors.country && touched.country && <div className={errors.country.type}>{errors.country.message}</div>}
                </div>

                <div className="mb-1 clear">
                  <div className={`input-field${errors.line1 && touched.line1 ? ' input-field-error' : ''}`}>
                    <input name="line1" placeholder="Address Line 1" onChange={updateAddress} value={address.line1} />
                  </div>
                  {errors.line1 && touched.line1 && <div className={errors.line1.type}>{errors.line1.message}</div>}
                </div>

                <div className="mb-1 clear">
                  <div className={`input-field${errors.postcode && touched.postcode ? ' input-field-error' : ''}`}>
                    <input name="postcode" placeholder="Post Code" onChange={updateAddress} value={address.postcode} />
                  </div>
                  {errors.postcode && touched.postcode && <div className={errors.postcode.type}>{errors.postcode.message}</div>}
                </div>

              </div>
            )}

            {isMoto === false && (
              <div className="input-container">
                <label className="container store-details-container">
                  <input type="checkbox" checked={storeFuturePayments} onChange={(e) => setStoreFuturePayments(e.target.checked)} />
                  <span className="checkmark" />
                  <span className="checkbox-label">Store card details for future payments</span>
                </label>
              </div>
            )}

            <div className="wpwl-group wpwl-group-submit wpwl-clearfix">
              <div className="wpwl-wrapper wpwl-wrapper-submit">
                <button className="wpwl-button wpwl-button-pay" type="submit" aria-label="Pay now">
                  Pay now
                  {
                    surchargeRule?.organisation_id && (
                        <small style={{
                          fontWeight: 100,
                          display: 'block',
                          fontSize: '11px',
                          marginTop: '-7px',
                          color: '#fef1f1',
                        }}>Including a { formatCurrency(((surchargeRule.modifierType === 'percentage' ? paymentDetails.amount * (surchargeRule.percentage / 100) : surchargeRule.amount) / 100), {}, currency, paymentDetails.currency)} surcharge</small>
                    )
                  }
                </button>
              </div>
            </div>
          </form>
        </React.Fragment>
      )}
    </div>
  );
}

export default function BasisTheoryComponent({ transactionMethod }) {
  // creates a news instance of BasisTheory class
  const { bt, error } = useBasisTheory("key_VvSn9We6Eg9e7gjHsV7oby", { elements: true });

  if (typeof bt !== 'undefined') {
    return (
      <BasisTheoryProvider bt={bt}>
        <CardForm bt={bt} transactionMethod={transactionMethod} />
      </BasisTheoryProvider>
    );
  }

  return <div />
};
