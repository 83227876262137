import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { Container } from "@material-ui/core";
import { formatCurrency } from "../../lib/currency";

import { useHistory } from 'react-router-dom';

import FullScreenLoader from '../../components/FullScreenLoader';
import API_ROUTES, {FRONTEND_ROUTES} from '../../config/api';

import '../../assets/css/success.css';
import CardImage from "./CardImage";
import {AUTHORIZED, PREAUTHORIZED} from "../../constants/payment-status";

export default function Processing (
  { match:
    {
      params: { transactionId }
    }
  }
) {
  const { currency } = useSelector((state) => state.application.enums);

  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);

  useEffect(() => {
    const getTransactionStatus = async() => {
      try {
        setLoading(true);

        const { data } = await axios.get(API_ROUTES.paymentLink["transaction-status:get"](transactionId));

        setData(data.data);

        setLoading(false);
      } catch(error) {
        setLoading(false);
        history.push("/error/transaction/not-found");
      }
    }

    getTransactionStatus();
  }, [transactionId]);

  if (loading) {
    return (
      <FullScreenLoader open={loading}>Finalising Payment...</FullScreenLoader>
    );
  }

  const getTitle = () => {
    const status = history?.location?.state?.status;

    if (status === AUTHORIZED || status === PREAUTHORIZED) {
      return 'Payment Authorised';
    }

    return 'Payment Successful';
  }

  return (
    <Container maxWidth="sm">
      <div className="page_success card">

        { data?.organisation?.image && (
          <img className="card__provider_img" alt="" src={`${FRONTEND_ROUTES.assets['base']()}${data.organisation.image}`} />
        )}

        <h1 className="card__msg">{getTitle()}</h1>

        <div className="card__body">
          <h1 className="card__price">{ formatCurrency((data?.amount / 100), {}, currency, data?.currency) }</h1>

          { data?.metadata?.last_four_digits && (
            <React.Fragment>
              <p className="card__method">Payment method</p>
              <div className="card__payment">
                <CardImage type={ data?.metadata?.payment_brand }/>
                <div className="card__card-details">
                  <p className="card__card-type">{ data?.metadata?.card_type } card</p>
                  <p className="card__card-number">ending in { data?.metadata?.last_four_digits } </p>
                </div>
              </div>
            </React.Fragment>
          )}

        </div>
        <div className="card__tags">
          <p>Our Reference: <strong>{ data?.id }</strong></p>
          <p>If you have any questions, please get in touch with <strong>{data?.organisation?.name}</strong>.</p>
        </div>
      </div>
    </Container>
  );
}
