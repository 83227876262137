import React from 'react';
import { useSelector } from 'react-redux';
import TotalProcessing from '../../../providers/total-processing';
import NuaPay from '../../../providers/nuapay';
import Nuvei from '../../../providers/nuvei';
import BasisTheory from '../../../providers/basis-theory';
import { CARD, OPEN_BANKING } from '../../../../constants/payment-method';
import CardLoading from "../../../../components/CardLoading";

const ProviderRenderer = () => {
  const { totalProcessing, application: { isEmbedded } } = useSelector((state) => state);
  const { payment: { paymentDetails, paymentMethod } } = useSelector((state) => state);

  if (!paymentDetails || !paymentDetails?.providers) {
    return <CardLoading />;
  }

  if (paymentMethod === CARD && paymentDetails.tokenisationProviders.indexOf('BASIS_THEORY') !== -1) {
    return <BasisTheory transactionMethod={paymentDetails.transactionMethod} />
  }

  if (paymentMethod === CARD && paymentDetails.providers.indexOf('TOTAL_PROCESSING') !== -1) {
    return (
      <div key={totalProcessing.reload}>
        <TotalProcessing transactionMethod={paymentDetails.transactionMethod} />
      </div>
    );
  } else if (paymentMethod === CARD && paymentDetails.providers.indexOf('NUVEI') !== -1) {
    return (
      <Nuvei transactionMethod={paymentDetails.transactionMethod} />
    );
  }

  if (
    paymentMethod === OPEN_BANKING &&
    paymentDetails.providers.indexOf('NUAPAY') !== -1 &&
    isEmbedded !== true
  ) {
    return (
      <NuaPay />
    )
  }

  return <React.Fragment />;
}

export default ProviderRenderer;
