import { combineReducers } from 'redux';
import basisTheoryReducer from './provider/basis-theory';
import paymentReducer from './paymentReducer';
import nuapayReducer from './provider/nuapay';
import totalProcessingReducer from "./provider/total-processing";
import nuveiReducer from "./provider/nuvei";
import applicationReducer from './applicationReducer';

export default combineReducers({
  application: applicationReducer,
  basisTheory: basisTheoryReducer,
  payment: paymentReducer,
  nuapay: nuapayReducer,
  totalProcessing: totalProcessingReducer,
  nuvei: nuveiReducer,
});
