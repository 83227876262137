import React from "react";
import Logo from "../components/Logo";

export default function Footer() {
  return (
    <footer className="footer">
      Travel payments by &nbsp;
      <Logo />
      <div className="footer-spacer" />
      <a href="https://www.felloh.com/privacy-policy" target="_blank" rel="noreferrer">Privacy Policy</a>
      <a href="https://www.felloh.com/cookie-policy" target="_blank" rel="noreferrer">Cookie Policy</a>
    </footer>
  );
}
