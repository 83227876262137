import React from 'react';

import {Skeleton} from "@material-ui/lab";

const CardLoading = () => (
  <div className="payment-form payment-form-loading">

    <div className="input-container">
      <div className="wpwl-label">Card Number</div>
      <Skeleton height="50px" />
    </div>

    <div className="input-container half-width mr-2">
      <div className="wpwl-label">Expiry Date</div>
      <Skeleton height="50px" />
    </div>

    <div className="input-container half-width">
      <div className="wpwl-label">CVC Number</div>
      <Skeleton height="50px" />
    </div>

    <div className="input-container">
      <div className="wpwl-label">Cardholder Name</div>
      <Skeleton height="50px" />
    </div>

    <div className="input-container">
      <div className="wpwl-label">Billing Address</div>
      <div className="mb-1">
        <Skeleton height="50px" />
      </div>
      <div className="mb-1">
        <Skeleton height="50px" />
      </div>
      <div className="mb-1">
        <Skeleton height="50px" />
      </div>
    </div>

    <div className="wpwl-group wpwl-group-submit wpwl-clearfix">
      <div className="wpwl-wrapper wpwl-wrapper-submit">
        <button className="wpwl-button wpwl-button-pay" type="submit" aria-label="Pay now" disabled={true}>Pay now</button>
      </div>
    </div>

  </div>
)

export default CardLoading;
