import * as Sentry from '@sentry/browser';

export const paymentRedirect = (
  success,
  transactionID,
  isEmbedded = false,
  returnString = false,
) => {
  let redirectUrl = window.location.protocol + '//' + window.location.host + '/error/processing';

  if (success && isEmbedded) {
    redirectUrl = window.location.protocol + '//' + window.location.host + '/embed/' + transactionID + '/processing';
  }

  if (success) {
    redirectUrl = window.location.protocol + '//' + window.location.host + '/' + transactionID + '/processing';
  }

  if (redirectUrl.indexOf('/error/processing') !== -1) {
    Sentry.captureException(new Error('Error - redirected to processing error page for unknown reason'));
  }

  if (returnString === true) {
    return redirectUrl;
  }

  window.location.href = redirectUrl;
}

export const parseNuveiError = (response, transactionID = null) => {
  const errorDescription = response.errorDescription.trim()?.toLowerCase();

  // See: https://docs.nuvei.com/documentation/accept-payment/web-sdk/nuvei-fields/#validation-error-handling
  // See: https://docs.nuvei.com/documentation/guides/testing/testing-cards/#cards-that-return-declined
  // Note: Codes below from a mixture of documentation and observed codes
  switch (errorDescription) {
    case 'incomplete_card_number':
    case 'incorrect_card_number':
      return 'There is a problem with the card number you entered. Please try again.';
    case 'incomplete_cvc':
    case 'invalid cvv':
    case 'carddata.cvv is invalid':
      return 'There is a problem with the CVV/CVC you entered. Please try again.';
    case 'incomplete_date':
    case 'date_in_past':
    case 'invalid_expiration_year':
      return 'There is a problem with the expiry date you entered. Please try again.';
    case 'mandatory fields are missing':
      return 'Mandatory fields are missing. Please try again.';
    case 'expired card':
      return 'The card you entered has expired. Please try again with another card.';
    case 'insufficient funds':
      return 'The card you entered has insufficient funds. Please try again with another card.';
    case 'decline':
    case 'soft decline - authentication is advised':
    case 'lost/stolen':
    case 'do not honor':
    case 'exceeds withdrawal limit':
    case 'exceeds withdrawal frequency':
    case 'transaction not permitted to cardholder':
    case 'transaction not permitted on terminal':
    case 'restricted card':
    case 'general 3d error': // observed
      return 'Card declined. Please try again.';
    case 'external error in processing':
    case 'acquirer validation':
    case 'invalid transaction':
    case 'format error':
    case 'issuer or switch inoperative':
    case 'timeout/retry':
    default:
      Sentry.captureException(new Error(`Unknown error code from Nuvei | ${transactionID} | ${errorDescription}`));
      return 'Something went wrong. Please try again.';
  }
}
