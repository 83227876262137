import React from "react";
import { ReactComponent as Logo } from '../../assets/img/404.svg';

export default function Error404() {
  return (
    <div className="page-404__wrapper">
      <Logo />
      <h1 className="nk-error-title">Oops! How did you get here?</h1>
      <h2 className="page-404__subtitle">
        This page you are looking for does not exist
      </h2>
    </div>
  );
}



