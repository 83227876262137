import React from 'react';
import { useSelector } from 'react-redux';
import { formatCurrency } from '../../../../lib/currency';
import { FRONTEND_ROUTES } from "../../../../config/api";
import Skeleton from 'react-loading-skeleton';

const capitalize = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

const capitalizeFirstLetter = (name) => {
  const splitName = name.split(" ").map(capitalize).join(" ");
  if (splitName.length > 25) {
    let sliced = splitName.slice(0, 25);
    return sliced + "...";
  } else {
    return splitName;
  }
};

const PriceDisplay = () => {
  const { payment: { paymentDetails, surchargeRule } } = useSelector((state) => state);
  const { currency } = useSelector((state) => state.application.enums);

  if (surchargeRule?.organisation_id) {
    const surchargeAmount = surchargeRule.modifierType === 'percentage' ? paymentDetails.amount * (surchargeRule.percentage / 100) : surchargeRule.amount;

    return (
      <React.Fragment>
        This is your payment link from <br />
        <span>'{paymentDetails.organisation.name}'</span>.
        <br />
        <br />
        To finish the process, please confirm the payment of{" "}
       <span>
          { formatCurrency(((paymentDetails.amount + surchargeAmount) / 100), {}, currency, paymentDetails.currency) }
       </span>

        <small style={{ color: '#d35400' }}> (including { formatCurrency(((surchargeAmount) / 100), {}, currency, paymentDetails.currency) } card processing fee)</small>

      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      This is your payment link from <br />
      <span>'{paymentDetails.organisation.name}'</span>.
      <br />
      <br />
      To finish the process, please confirm the payment of{" "}
      <span>{formatCurrency((paymentDetails.amount / 100), {}, currency, paymentDetails.currency)}</span>
      <br />
      <br />
    </React.Fragment>
  );
};

const PaymentDetails = ({ initiating }) => {
  const { payment: { paymentDetails } } = useSelector((state) => state);

  if (initiating || !paymentDetails.id) {
    return (
      <div className="app-content__left-block">
        <div className="app__merchant-logo">
          <Skeleton />
        </div>

        <h1 className="app__dynamic-text">
          <Skeleton style={{ height: '40px' }} />
        </h1>

        <h2 className="app__dynamic-text">
          <Skeleton count={2} style={{ height: '40px' }}/>
          <br />
          <Skeleton count={2} style={{ height: '40px' }}/>
        </h2>
      </div>
    )
  }

  return (
    <div className="app-content__left-block">

      {paymentDetails.id && paymentDetails.organisation.image && (
        <div className="app__merchant-logo">
          <img
            src={`${FRONTEND_ROUTES.assets['base']()}${paymentDetails.organisation.image}`}
            alt="Merchant Logo"
          />
        </div>
      )}

      { paymentDetails.customer_name && (
        <h1 className="app__dynamic-text">
          Hi <span>{capitalizeFirstLetter(paymentDetails.customer_name)}</span>,
        </h1>
      )}

      <h2 className="app__dynamic-text">
        <PriceDisplay />
      </h2>
    </div>
  )
}

export default PaymentDetails;
