import React, { memo, useEffect, useRef, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

import { transformCurrency } from '../../../../lib/currency';
import { paymentRedirect } from '../../../../utils';
import {useSelector} from "react-redux";
import * as Sentry from "@sentry/browser";

// See: https://docs.nuvei.com/documentation/global-guides-new/google-pay/google-pay-guide-web-sdk/
// See: https://jsfiddle.net/nuvei/g1e2mjv3/

const INIT_COUNTRY = 'GB';

const GATEWAY = 'nuveidigital';

const getGpayMerchantByEnv = () => process.env.REACT_APP_API_ENV === 'production' ? 'nuveidigital' : 'googletest';

const tokenizationSpecification = {
  type: 'PAYMENT_GATEWAY', // 'PAYMENT_GATEWAY', 'DIRECT'
  parameters: {
    gateway: GATEWAY,
    gatewayMerchantId: getGpayMerchantByEnv(),
  }
};

const baseRequest = {
  apiVersion: 2,
  apiVersionMinor: 0,
};

const getGpayTransactionInfo = (paymentDetails, currency) => (
  {
    countryCode: INIT_COUNTRY,
    currencyCode: transformCurrency(currency, paymentDetails.currency),
    totalPrice: (paymentDetails.amount / 100).toString(),
    totalPriceStatus: 'FINAL',
    checkoutOption: 'COMPLETE_IMMEDIATE_PURCHASE',
  }
);

const getGpayDataRequest = (googlepayMerchantId, googlepayMerchantName, cardPaymentMethod) => {
  const paymentDataRequest = Object.assign({}, baseRequest);
  paymentDataRequest.allowedPaymentMethods = [cardPaymentMethod];
  paymentDataRequest.transactionInfo = null; // Set separately
  paymentDataRequest.merchantInfo = {
    // @todo a merchant ID is available for a production environment after approval by Google
    // See {@link https://developers.google.com/pay/api/web/guides/test-and-deploy/integration-checklist|Integration checklist}
    merchantId: googlepayMerchantId,
    merchantName: googlepayMerchantName,
  };
  paymentDataRequest.emailRequired = true;
  return paymentDataRequest;
};

export const NuveiGooglepay = memo(({ safeCharge, gpayApi, paymentDetails, sessionToken, transactionID, currency, googlepayMerchantId, googlepayMerchantName, googlepayAllowedCardNetworks }) => {
  const { application: { isEmbedded }  } = useSelector((state) => state);
  const gpayRef = useRef();
  const [processing, setProcessing] = useState(false);

  const baseCardPaymentMethod = {
    type: 'CARD',
    parameters: {
      allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
      allowedCardNetworks: googlepayAllowedCardNetworks,
      allowCreditCards: true,
      allowPrepaidCards: false,
      assuranceDetailsRequired: false,
      billingAddressRequired: true,
      billingAddressParameters: {
        format: 'FULL', // 'MIN', 'FULL'
        phoneNumberRequired: false,
      },
    },
  };

  const cardPaymentMethod = Object.assign({},
    baseCardPaymentMethod, {
    tokenizationSpecification: tokenizationSpecification,
  }
  );

  useEffect(() => {
    if (!gpayApi) return;

    gpayInit();
  }, [gpayApi]);

  const gpayInit = async () => {
    try {
      // https://developers.google.com/pay/api/web/reference/request-objects#IsReadyToPayRequest
      const response = await gpayApi.isReadyToPay(getGpayBaseParams());
      if (response && response.result === true) {
        addGpayButton();
        prefetchGpayData(paymentDetails, currency);
      }
    } catch (err) {
      Sentry.captureException(err);
    }
  };

  const getGpayBaseParams = () => {
    return Object.assign({},
      baseRequest, {
        allowedPaymentMethods: [baseCardPaymentMethod]
      }
    );
  };

  const addGpayButton = () => {
    const button = gpayApi.createButton({
      buttonSizeMode: 'fill',
      onClick: triggerPayment,
    });

    // Ensure only a single button is ever present
    while (gpayRef.current.firstChild) {
      gpayRef.current.removeChild(gpayRef.current.firstChild);
    }

    // Add button to container ref
    gpayRef.current.appendChild(button);
  };

  const prefetchGpayData = (paymentDetails, currency) => {
    const paymentDataRequest = getGpayDataRequest(googlepayMerchantId, googlepayMerchantName, cardPaymentMethod);
    paymentDataRequest.transactionInfo = {
      totalPriceStatus: 'NOT_CURRENTLY_KNOWN',
      currencyCode: transformCurrency(currency, paymentDetails.currency),
    };
    gpayApi.prefetchPaymentData(paymentDataRequest);
  };

  const triggerPayment = async () => {
    const paymentDataRequest = getGpayDataRequest(googlepayMerchantId, googlepayMerchantName, cardPaymentMethod);
    paymentDataRequest.transactionInfo = getGpayTransactionInfo(paymentDetails, currency);

    try {
      // https://developers.google.com/pay/api/web/reference/response-objects#PaymentData
      const providerResponse = await gpayApi.loadPaymentData(paymentDataRequest);
      createPayment(providerResponse);
    } catch (err) {
      Sentry.captureException(err);
    }
  }

  const createPayment = (providerResponse) => {
    if (!providerResponse) {
      return;
    }

    setProcessing(true);

    const paymentToken = JSON.stringify(providerResponse?.paymentMethodData);
    const email = providerResponse?.email;
    const billingAddress = providerResponse?.paymentMethodData?.info?.billingAddress;

    const data = {
        sessionToken: sessionToken,
        paymentOption: {
          card: {
            externalToken: {
              externalTokenProvider: 'GooglePay',
              mobileToken: paymentToken,
            }
          }
        },
        cardHolderName: billingAddress?.name,
        billingAddress: {
          email: email,
          country: billingAddress?.countryCode,
          address: billingAddress?.address1,
          city: billingAddress?.locality,
          state: billingAddress?.administrativeArea,
          zip: billingAddress?.postalCode,
        },
    };

    try {
      safeCharge.createPayment(
        data,
        (response) => {
          if (response.result === 'APPROVED') {
            setProcessing(false);
            paymentRedirect(true, transactionID, isEmbedded);
          } else {
            setProcessing(false);
            // TODO: Handle errors usefully. Redirect to error page?
          }
        }
      );
    } catch (err) {
      Sentry.captureException(err);
      paymentRedirect(false, transactionID, isEmbedded);
    }
  };

  return (
    <div style={{minHeight: '3em'}}>
      {!processing && <div className="pullLeft" ref={gpayRef} style={{ width: '100%' }}></div>}
      {processing && (
        <div className="pullLeft" style={{ width: '100%' }}>
          <div className="card_frame__loading">
            <FontAwesomeIcon icon={faSpinner} spin />
          </div>
        </div>
      )}
    </div>
  );
});
