import React, { useEffect, useState } from 'react';
import axios from 'axios';

import { useHistory } from 'react-router-dom';

import FullScreenLoader from '../../components/FullScreenLoader';
import API_ROUTES from '../../config/api';
import TransactionHandler from './transaction-handler';
import {setEmbedded, setJourneyProgress, setTransactionID} from "../../action/application";
import {PROCESSING} from "../../constants/journey-progress";
import {useDispatch} from "react-redux";
import * as Sentry from "@sentry/browser";

export default function Processing (
  { match:
    {
      params: { transactionId }
    },
    isEmbedded,
  }
) {
  const dispatch = useDispatch();
  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);

  const embedded = isEmbedded === true;

  useEffect(() => {
    dispatch(setEmbedded(embedded));
  }, [embedded]);

  useEffect(() => {
    dispatch(setJourneyProgress(PROCESSING));
    dispatch(setTransactionID(transactionId));

    const getTransactionStatus = async() => {
      try {
        setLoading(true);

        const { data } = await axios.get(API_ROUTES.paymentLink["transaction-status:get"](transactionId));

        setData(data.data);
      } catch(error) {
        Sentry.captureException(error);
        setLoading(false);
        history.push("/error/processing");
      }
    }

    getTransactionStatus();
  }, [transactionId]);

  return (
    <div className="app-content__wrapper page_processing">
      <FullScreenLoader open={loading}>Processing Payment...</FullScreenLoader>

      { data && (
        <TransactionHandler data={data} isEmbedded={isEmbedded} />
      ) }
    </div>
  );
}
