import React, { useEffect, useState, useRef, useCallback } from 'react';
import axios from 'axios';

import { useHistory } from 'react-router-dom';
import {useDispatch} from 'react-redux';

import { updatePaymentDetails } from '../../action/payment';
import API_ROUTES from '../../config/api';
import { useQuery } from '../../lib/querystring';
import { validateTransactionMethod } from '../../constants/transaction-method';
import PaymentDetails from './components/payment-details';
import MethodSelector from './components/method-selector';
import ProviderRenderer from './components/provider-renderer';
import ErrorBanner from './components/error-banner';
import {setEmbedded} from "../../action/application";
import posthog from 'posthog-js'

export default function Payment(
  { match:
    {
      params: { transactionId }
    },
    isEmbedded
  }
) {
  const mountedRef = useRef(true);
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const query = useQuery();

  const embedded = isEmbedded === true;

  useEffect(() => {
    dispatch(setEmbedded(embedded));
  }, [embedded]);

  const getPaymentLink = useCallback(async () => {
    try {
      setLoading(true);

      const transactionMethod = validateTransactionMethod(
        query.get('method'),
        query.has('manual'),
        query.has('manualInPerson'),
        transactionId,
      );
      const paymentProvider = query.get('provider');

      const { data } = await axios.get(API_ROUTES.paymentLink["payment:get"](
        transactionId,
        embedded === true ? 'ecommerce' : 'payment-link',
        transactionMethod,
        paymentProvider,
      ));

      if (data.data.status === 'paid') {
        history.push("/error/link/paid");
      } else if (data.data.status === 'expired') {
        history.push("/error/link/expired");
      }

      if (posthog.__loaded) {
        posthog.capture(
          embedded === true ? 'ecommerce-load' : 'payment-link-load',
          { $set_once: { [embedded === true ? 'ecommerceID' : 'paymentLinkID']: transactionId } }
        )

        posthog.identify(transactionId);

        if (data?.data?.organisation?.id) {
          posthog.group('company', data?.data?.organisation?.id)
        }

        if (data?.data?.customer_name) {
          posthog.people.set({ name: data?.data?.customer_name })
        }
      }

      dispatch(updatePaymentDetails({ ...data.data, transactionId }));

      setLoading(false);
    } catch(error) {
      history.push("/error/link/not-found");
    }
  }, [mountedRef]);

  useEffect(() => {
    getPaymentLink();

    return () => {
      mountedRef.current = false;
    };
  }, []);

  if (isEmbedded) {
    return (
      <div>
        <ErrorBanner />
        <div className={`app-content__right-block${ isEmbedded ? ' form-embedded' : '' }`}>
          <MethodSelector />
          <ProviderRenderer />
        </div>
      </div>
    );
  }

  return (
    <div>
      <ErrorBanner />
      <div className="app-content__wrapper">
        <div className="app-content">
          <PaymentDetails initiating={loading} />

          <div className="app-content__right-block">
            <MethodSelector initiating={loading} />
            <ProviderRenderer initiating={true} />
          </div>
        </div>
      </div>
    </div>
  );
}
