import { SET_LOADING } from "../../../action/provider/basis-theory";

export const initialState = {
  loading: true,
};

const BasisTheoryReducer = (state = initialState, action) => {
  switch (action.type) {

    case SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      }

    default:
      return state;
  }
};

export default BasisTheoryReducer;
