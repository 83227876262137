import {ONLINE} from "../../constants/transaction-method";

const environment = process.env.REACT_APP_API_ENV;

export const FRONTEND_ENDPOINTS = {
  assets: {
    dev: 'https://assets.staging.felloh.com/',
    pr: 'https://assets.staging.felloh.com/',
    sandbox: 'https://assets.sandbox.felloh.com/',
    staging: 'https://assets.staging.felloh.com/',
    production: 'https://assets.felloh.com/',
  },
};

export const API_ENDPOINTS = {
  basis_theory: {
    dev: 'http://localhost:3295/',
    pr: 'https://bt.staging.felloh.com/',
    sandbox: 'https://bt.sandbox.felloh.com/',
    staging: 'https://bt.staging.felloh.com/',
    production: 'https://bt.api.felloh.com/',
  },
  enums: {
    dev: 'http://localhost:3030/',
    pr: 'https://enums.staging.felloh.com/',
    sandbox: 'https://enums.sandbox.felloh.com/',
    staging: 'https://enums.staging.felloh.com/',
    production: 'https://enums.api.felloh.com/',
  },
  nuapay: {
    dev: 'http://localhost:3004/',
    pr: 'https://nuapay.staging.felloh.com/',
    sandbox: 'https://nuapay.sandbox.felloh.com/',
    staging: 'https://nuapay.staging.felloh.com/',
    production: 'https://nuapay.api.felloh.com/',
  },
  payment: {
    dev: 'http://localhost:3100/',
    pr: 'https://payment.staging.felloh.com/',
    sandbox: 'https://payment.sandbox.felloh.com/',
    staging: 'https://payment.staging.felloh.com/',
    production: 'https://payment.api.felloh.com/',
  },
  total_processing: {
    dev: 'http://localhost:3190/',
    pr: 'https://total-processing.staging.felloh.com/',
    sandbox: 'https://total-processing.sandbox.felloh.com/',
    staging: 'https://total-processing.staging.felloh.com/',
    production: 'https://total-processing.api.felloh.com/',
  },
  nuvei: {
    dev: 'http://localhost:3192/',
    pr: 'https://nuvei.staging.felloh.com/',
    staging: 'https://nuvei.staging.felloh.com/',
    production: 'https://nuvei.api.felloh.com/',
  },
  planet: {
    dev: 'http://localhost:3190/',
    staging: 'https://planet.staging.felloh.com/',
    sandbox: 'https://planet.sandbox.felloh.com/',
    production: 'https://planet.api.felloh.com/',
  },
};

export const FRONTEND_ROUTES = {
  assets: {
    'base': () => FRONTEND_ENDPOINTS.assets[environment],
  },
};

export const API_ROUTES = {
  basisTheory: {
    'bin:lookup': () => `${API_ENDPOINTS.basis_theory[environment]}bin`,
    'card-link:get': (id, paymentInitiationType, transactionMethod) => {
      if (transactionMethod !== ONLINE) {
        return `${API_ENDPOINTS.basis_theory[environment]}payment-initiation/${paymentInitiationType}/${id}?moto=true&moto_method=${transactionMethod}`;
      }

      return `${API_ENDPOINTS.basis_theory[environment]}payment-initiation/${paymentInitiationType}/${id}`;
    },
  },
  enums: {
    'enums:get': () => `${API_ENDPOINTS.enums[environment]}`,
  },
  nuapay: {
    'banks:get': (id, paymentInitiationType) =>
      `${API_ENDPOINTS.nuapay[environment]}payment-initiation/${paymentInitiationType}/${id}/banks`,
    'payment:initiate': (id, paymentInitiationType) =>
      `${API_ENDPOINTS.nuapay[environment]}payment-initiation/${paymentInitiationType}/${id}`,
    'payment:status': (id) => `${API_ENDPOINTS.nuapay[environment]}payment/${id}/status`,
  },
  paymentLink: {
    'payment:get': (id, type, method, provider = false) =>
      `${API_ENDPOINTS.payment[environment]}payment/${type}/${id}?method=${method}${provider ? `&provider=${provider}` : ''}`,
    'transaction-status:get': (id) => `${API_ENDPOINTS.payment[environment]}transaction-status/${id}`,
    'countries:list': () => `${API_ENDPOINTS.payment[environment]}countries`,
  },
  planet: {
    'payment:status': (id) => `${API_ENDPOINTS.planet[environment]}payment/${id}/status`,
  },
  totalProcessing: {
    'card-link:get': (id, paymentInitiationType, transactionMethod) => {
      if (transactionMethod !== ONLINE) {
        return `${API_ENDPOINTS.total_processing[environment]}payment-initiation/${paymentInitiationType}/${id}?moto=true&moto_method=${transactionMethod}`;
      }

      return `${API_ENDPOINTS.total_processing[environment]}payment-initiation/${paymentInitiationType}/${id}`;
    },
    'payment:status': (id) => `${API_ENDPOINTS.total_processing[environment]}payment/${id}/status`,
  },
  nuvei: {
    'card-link:get': (id, paymentInitiationType, transactionMethod) => {
      if (transactionMethod !== ONLINE) {
        return `${API_ENDPOINTS.nuvei[environment]}payment-initiation/${paymentInitiationType}/${id}?moto=true&moto_method=${transactionMethod}`;
      }

      return `${API_ENDPOINTS.nuvei[environment]}payment-initiation/${paymentInitiationType}/${id}`;
    },
    'payment:status': (id) => `${API_ENDPOINTS.nuvei[environment]}payment/${id}/status`,
  },
};

export default API_ROUTES;
