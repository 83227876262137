const LOCALE = 'en';
const DEFAULT_CURRENCY = 'GBP';

export function formatCurrency(number, options = {}, currencyEnums={}, currency=null) {
  if (isNaN(number)) return;
  if (!Object.keys(currencyEnums).length) return;

  const majorCurrency = transformCurrency(currencyEnums, currency);

  const isInteger = Number.isInteger(Number(number));

  return new Intl.NumberFormat(LOCALE, {
    style: "currency",
    currency: majorCurrency,
    maximumFractionDigits: 2, // Never more than 2
    minimumFractionDigits: isInteger ? 0 : 2, // Always zero or 2 decimals
    ...options,
  }).format(number);
}

export function transformCurrency(currencyEnums={}, currency=null) {
  if (!Object.keys(currencyEnums).length) return;

  let majorCurrency = DEFAULT_CURRENCY;
  if (currency !== null) {
    const currencyMeta = currencyEnums[currency];
    majorCurrency = currencyMeta?.major_unit;
  }

  return majorCurrency;
}
