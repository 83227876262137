import React from "react";

const CardLogo = (props) => {
  return (
    <>
      <img
        alt="Logo"
        style={{ width: props.width, paddingBottom: props.padding ? props.padding : "0px" }}
        src={props.src}
        height="1%"
        {...props}
      />
    </>
  );
};

export default CardLogo;
