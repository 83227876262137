import { UPDATE_PAYMENT_METHOD, UPDATE_PAYMENT_DETAILS, UPDATE_SURCHARGE_RULE, RESET_STATE } from "../action/payment";
import { CARD } from '../constants/payment-method';

export const initialState = {
  paymentDetails: {},
  paymentMethod: CARD,
  surchargeRule: {},
};

const paymentReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_PAYMENT_METHOD:
      return {
        ...state,
        paymentMethod: action.payload,
      }

    case UPDATE_PAYMENT_DETAILS:
      return {
        ...state,
        paymentDetails: action.payload,
      };

    case UPDATE_SURCHARGE_RULE:
      return {
        ...state,
        surchargeRule: action.payload,
      }

    case RESET_STATE:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default paymentReducer;
