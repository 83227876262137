import { all } from 'redux-saga/effects';
import Application from './application';
import Payment from './payment';

/**
 * Saga Reducer Hooks
 */
export default function* sagas() {
  yield all([
    Application(),
    Payment(),
  ]);
}
