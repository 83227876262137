export const NUVEI_UPDATE_CONFIG = '@provider/nuvei/update-config';
export const NUVEI_SET_LOADED = '@provider/nuvei/set-loaded';

export const setLoaded = (loaded) => {
  return {
    type: NUVEI_SET_LOADED,
    payload: loaded,
  };
};


export const updatePaymentConfiguration = (sessionToken, merchantID, merchantSiteID, transactionID) => {
  return {
    type: NUVEI_UPDATE_CONFIG,
    payload: {
      sessionToken,
      merchantID,
      merchantSiteID,
      transactionID,
      loaded: true,
    },
  };
};
