import React from 'react';
import { useLocation } from "react-router-dom";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const ErrorBanner = () => {
  let query = useQuery();
  const error = query.get('error');
  const message = query.get('message');

  if (error === 'defined') {
    return (
      <div className="error-container">
        { message }
      </div>
    );
  }

  if (error === 'timeout') {
    return (
      <div className="error-container">
        Your transaction failed to process within the required time limit, please try again.
      </div>
    )
  }

  if (error === 'declined') {
    return (
      <div className="error-container">
        Your transaction was declined, please try again.
      </div>
    )
  }

  if (error === 'misc-error') {
    return (
      <div className="error-container">
        An error has occurred with your transaction, please try again.
      </div>
    )
  }

  return (
    <React.Fragment />
  )
}

export default ErrorBanner;
