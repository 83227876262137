import React, { useEffect, useState } from "react";

import ListOfBanks from "../../../components/ListOfBanks";
import QRCode from "qrcode.react";
import { Typography } from "@material-ui/core";
import {useSelector} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";
import axios from "axios";
import API_ROUTES from "../../../config/api";
import * as Sentry from "@sentry/browser";
import getBrowserFingerprint from 'get-browser-fingerprint';

export default function Nuapay() {
  const { payment: { paymentDetails }, nuapay, application: { isEmbedded } } = useSelector((state) => state);
  const [bankList, setBankList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingText, setLoadingText] = useState('Fetching available banking providers');
  const [showQR, setShowQR] = useState(false);
  const [filteredList, setFilteredList] = useState(null != bankList ? [...bankList] : []);
  const [redirectUrl, setRedirectUrl] = useState(null);
  const [showError, setShowError] = useState(false);

  const history = useHistory();

  useEffect(() => {
    setBankList(nuapay.banks);
    setShowError(false);
    setFilteredList(nuapay.banks);
    setLoading(nuapay.loaded === false);
  }, [nuapay]);

  /**
   * Handle open banking bank selection
   * @param bankDetails
   * @returns {Promise<void>}
   */
  const handleClick = async (bankDetails) => {
    try {
      const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
      setLoadingText(`Transferring to ${bankDetails.name} ...`);
      setLoading(true);

      const returnedURL = await initiatePayment(bankDetails);

      if (!isMobile) {
        setShowQR(true);
        setLoading(false);
      } else {
        window.location.href = returnedURL;
      }
    } catch (error) {
      Sentry.captureException(error);
      history.push("/error/processing");
    }
  };

  const initiatePayment = async (bankDetails) => {
    const { data } = await axios.post(
      API_ROUTES.nuapay['payment:initiate'](
        paymentDetails.id,
        isEmbedded === true ? 'ecommerce' : 'payment-link',
      ),
      {
        bank_id: bankDetails.id,
        identifier: getBrowserFingerprint(),
      },
    );

    setRedirectUrl(data.data.url);

    return data.data.url;
  }

  /**
   * Handle user search param change
   * @param event
   */
  const handleSearchTextChange = (event) => {
    if (event.target.value === "") {
      setFilteredList([...bankList]);
    } else {
      const newList = filteredList.filter((bank) =>
        bank.name.toUpperCase().includes(event.target.value.toUpperCase())
      );

      setFilteredList(newList);
    }
  };

  if (loading === true) {
    return (
      <div className="card_frame__loading">
        <h2>{ loadingText }</h2>
        <FontAwesomeIcon icon={faSpinner} spin />
      </div>
    )
  }

  return (
    <div id="provider_nuapay">
      <>
        {showQR && (
          <div className="qrcode__wrapper" style={{ textAlign: "center" }}>
            <h2>
              Scan the QR Code with your phone to open your Mobile Banking App or click Continue button below to open Online
              Banking.
            </h2>
            <QRCode
              id="QR_CODE"
              value={redirectUrl}
              size={300}
              level={"L"}
              fgColor="#000000"
              includeMargin={true}
              className="qrcode-element"
            />

            <button type="button" className="online-button" onClick={() => window.location.href = redirectUrl}>
              Continue to Online Banking
            </button>
          </div>
        )}
        {!showQR && (
          <div>
            {!showError && (
              <>
                <p>
                  We'll securely transfer you to your bank account to complete the transaction. Bank
                  details are not shared with {paymentDetails?.organisation?.name}.
                </p>
                <div className="search-bank__wrapper">
                  <input
                    type="search"
                    onChange={handleSearchTextChange}
                    placeholder="Search for your bank"
                    className="search-bank"
                  />
                </div>
                <ListOfBanks
                  bankList={filteredList}
                  onBankClick={(bank) => {
                    handleClick(bank);
                  }}
                />
              </>
            )}
            {showError && (
              <div>
                <Typography>
                  We are currently having trouble loading the page. Please try again later or pay
                  using Credit/Debit Card.
                </Typography>
              </div>
            )}
          </div>
        )}
      </>
    </div>
  );
}
