import React, {useEffect} from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import API_ROUTES from '../../../config/api';
import {
  AUTHORIZED,
  PENDING,
  COMPLETE,
  DECLINED,
  REJECTED,
  TIMEOUT,
  PREAUTHORIZED,
} from '../../../constants/payment-status';
import * as Sentry from '@sentry/browser';
import {useDispatch} from "react-redux";
import {setJourneyProgress} from "../../../action/application";
import { DECLINED as DECLINED_EVENT, SUCCESS as SUCCESS_EVENT } from "../../../constants/journey-progress";

const TransactionHandler = ({ data, isEmbedded }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const providerCheck = async (status, data, refresh, counter) => {
    if (refresh === true && counter !== 0) {
      await new Promise(function(resolve){
        setTimeout(resolve, counter < 10 ? 2000 : 5000);
      });
    }

    console.log(data.provider)
    if (
      data && ['NUAPAY', 'TOTAL_PROCESSING', 'NUVEI', 'PLANET'].includes(data.provider)
    ) {
      try {
        if (data.provider === 'NUAPAY') {
          const transaction = await axios.get(API_ROUTES.nuapay["payment:status"](data.id));
          return HandleStatus(transaction.data.data.status, data, true, counter + 1);
        } else if (data.provider === 'TOTAL_PROCESSING') {
          const transaction = await axios.get(API_ROUTES.totalProcessing["payment:status"](data.id));
          return HandleStatus(transaction.data.data.status, data, true, counter + 1);
        } else if (data.provider === 'NUVEI') {
          const transaction = await axios.put(API_ROUTES.nuvei["payment:status"](data.id));
          return HandleStatus(transaction.data.data.status, data, true, counter + 1);
        } else if (data.provider === 'PLANET') {
          const transaction = await axios.get(API_ROUTES.planet["payment:status"](data.id));
          return HandleStatus(transaction.data.data.status, data, true, counter + 1);
        }
      } catch (error) {
        Sentry.captureException(error);

        return HandleStatus(status, data, true, counter + 1);
      }
    } else {
      Sentry.captureException(new Error('Error - no provider initialised!'));
      history.push("/error/processing");
    }
  };

  const HandleStatus = async (status, data, refresh = false, counter = 0) => {
    if (counter === 50) {
      Sentry.captureException(new Error(`Error - no status returned on transaction and it has reached limit (provider = ${data.provider}).`));
      history.push("/error/processing");
    }

    switch(status) {
      case PENDING:
        await providerCheck(status, data, refresh, counter);
        break;
      case AUTHORIZED:
      case PREAUTHORIZED:
      case COMPLETE:
        dispatch(setJourneyProgress(SUCCESS_EVENT));

        if (isEmbedded !== true) {
          if (data?.payment_link?.success_url) {
            let addendum = '';

            if (data?.provider_reference) {
              addendum += data?.payment_link?.success_url.indexOf('?') !== -1 ? '&' : '?';
              addendum += `id=${data.provider_reference}`
            }

            // NOTE: Observed problems using this. Possible ID-mismatch on "success" page (paymentLinkID vs transactionID)
            window.location.href = data.payment_link.success_url + addendum;
          } else {
            // If "success_url" is not set, fall-back to this method (checked & working)
            const transactionID = data.id;
            history.push(`/${transactionID}/success`, { status });
          }
        }
        break;
      case DECLINED:
      case REJECTED:
        dispatch(setJourneyProgress(DECLINED_EVENT));

        if (isEmbedded === true) {
          history.push(`/embed/${data.ecommerce.id}?error=declined`);
        } else {
          history.push(`/${data.payment_link.id}?error=declined`);
        }
        break;
      case TIMEOUT:
        if (isEmbedded === true) {
          history.push(`/embed/${data.ecommerce.id}?error=timeout`);
        } else {
          history.push(`/${data.payment_link.id}?error=timeout`);
        }
        break;
      default:
        Sentry.captureException(new Error(`Error - unhandled status ${status} returned, redirected to error page (provider = ${data.provider})`));
        if (isEmbedded === true && data?.ecommerce?.id) {
          history.push(`/embed/${data.ecommerce.id}?error=timeout`);
        } else if (data?.payment_link?.id) {
          history.push(`/${data.payment_link.id}?error=timeout`);
        } else {
          history.push("/error/processing");
        }
    }
  };

  useEffect(() => {
    HandleStatus(data.status, data, true);
  }, []);

  return (
    <React.Fragment />
  );
}

export default TransactionHandler;
