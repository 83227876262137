/* eslint-disable import/prefer-default-export */
import { applyMiddleware, createStore, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension';
import rootReducer from '../reducer';
import sagas from '../saga';
import throttle from 'lodash.throttle';

import { triggerLoad } from '../action/application';
import { saveState, loadState } from './persistent-state';

export function configureStore(preloadedState = {}) {
  const middlewares = [thunkMiddleware];

  const applicationState = Object.keys(preloadedState).length >= 1 ? preloadedState : loadState();

  const sagaMiddleware = createSagaMiddleware();
  middlewares.push(sagaMiddleware);

  const middlewareEnhancer = composeWithDevTools(
    applyMiddleware(...middlewares)
  );

  const enhancers = [middlewareEnhancer];
  const composedEnhancers = compose(...enhancers);

  const store = createStore(
    rootReducer,
    applicationState,
    composedEnhancers,
  );

  sagaMiddleware.run(sagas);

  store.dispatch(triggerLoad());

  store.subscribe(throttle(() => {
    const state = store.getState();
    saveState(state);
  }, 1000));

  return store;
}
