import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter as Router } from "react-router-dom";
import ScrollToTop from "./common/ScrollToTop";
import { Provider } from 'react-redux';

// Global styles
import "./Styles.css";
import 'react-loading-skeleton/dist/skeleton.css'
import { configureStore } from './store';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import posthog from 'posthog-js'

const store = configureStore();

if (typeof process.env.REACT_APP_POSTHOG_KEY !== 'undefined') {
  posthog.init(process.env.REACT_APP_POSTHOG_KEY, { api_host: 'https://analytics.felloh.com' });
}

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],
  environment: process.env.REACT_APP_API_ENV,
  tracesSampleRate: 1.0,
  replaysOnErrorSampleRate: 1.0,
  replaysSessionSampleRate: 0,
  beforeSend(event) {
    if (
      event.exception.values.length >= 1 &&
      event?.exception?.values[0].value?.indexOf("Failed to set the 'href' property on 'Location'") !== -1
    ) {
      try {
        const url = event.exception.values[0].value.split("navigate the target frame to '")[1].split('/processing')[0];

        window.parent.postMessage(JSON.stringify({
          iframeRedirect: `${url}/processing`,
        }), '*');
      } catch (error) {
        throw error;
      }

      return null;
    }

    if (event.message === 'ResizeObserver loop limit exceeded') {
      return null;
    }

    return event;
  }
});

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <ScrollToTop />
      <App />
    </Router>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
