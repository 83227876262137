import { UPDATE_PAYMENT_CONFIGURATION, SET_LOADED, RELOAD } from "../../../action/provider/total-processing";

export const initialState = {
  transactionID: null,
  checkoutID: null,
  loaded: false,
  reload: 1,
};

const TotalProcessingReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_PAYMENT_CONFIGURATION:
      return {
        ...state,
        ...action.payload,
        loaded: true,
      }

    case SET_LOADED:
      return {
        ...state,
        loaded: action.payload,
      }

    case RELOAD:
      return {
        ...state,
        reload: new Date().getTime(),
      }

    default:
      return state;
  }
};

export default TotalProcessingReducer;
