export const UPDATE_PAYMENT_CONFIGURATION = '@provider/total-processing/update-payment-configuration';
export const SET_LOADED = '@provider/total-processing/set-loaded';
export const RELOAD = '@provider/total-processing/reload';

export const reload = () => {
  return {
    type: RELOAD,
    payload: new Date().getTime(),
  };
};

export const setLoaded = (loaded) => {
  return {
    type: SET_LOADED,
    payload: loaded,
  };
};


export const updatePaymentConfiguration = (checkoutID, transactionID) => {
  return {
    type: UPDATE_PAYMENT_CONFIGURATION,
    payload: {
      checkoutID,
      transactionID,
      loaded: true,
    },
  };
};
