import { UPDATE_BANKS, BANKING_UNAVAILABLE } from "../../../action/provider/nuapay";

export const initialState = {
  banks: [],
  loaded: false,
  unavailable: false,
};

const NuapayReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_BANKS:
      return {
        ...state,
        banks: action.payload,
        loaded: action.payload && action.payload.length >= 1,
        unavailable: false,
      }

    case BANKING_UNAVAILABLE:
      return {
        ...state,
        unavailable: action.payload,
      };

    default:
      return state;
  }
};

export default NuapayReducer;
