import React, { memo, useEffect, useState } from "react";

import {Grid, Typography, TextField, Button, withStyles} from "@material-ui/core";
import { Autocomplete } from '@material-ui/lab';

import { isMoto } from '../../../../constants/transaction-method';
import { paymentRedirect, parseNuveiError } from '../../../../utils';

import './index.css';
import { useSelector } from "react-redux";
import FullScreenLoader from "../../../../components/FullScreenLoader";
import * as Sentry from "@sentry/browser";

const DEFAULT_COUNTRY = 'GB';

// Ref: https://docs.nuvei.com/documentation/accept-payment/web-sdk/nuvei-fields/nuvei-fields-styling/
const nuveiStyling = {
  base: {
    fontSize: '16px',
    fontFamily: 'Inter, sans-serif',
    color: "#000000",
    fontSmoothing: 'antialiased',
    '::placeholder': {
      color: '#999999',
    },
  },
};

const NuveiTextField = withStyles({
  root: {
    '& input + fieldset': {
      borderColor: '#ffffff',
      borderWidth: '1px !IMPORTANT',
      borderRadius: '10px !IMPORTANT',
    },
    '& input:focus': {
      borderWidth: '1px !IMPORTANT',
      borderRadius: '10px !IMPORTANT',
    },
  },
})(TextField);

const NuveiAutocomplete = withStyles({
  root: {
    '& input + fieldset': {
      borderColor: '#ffffff',
      borderWidth: '1px !IMPORTANT',
      borderRadius: '10px !IMPORTANT',
    },
    '& input:focus': {
      borderWidth: '1px !IMPORTANT',
      borderRadius: '10px !IMPORTANT',
    },
  },
})(Autocomplete);

const NuveiButton = withStyles({
  root: {
    backgroundColor: "rgba(0, 0, 0, 0.65)",
    color: "var(--white)",
  },
})(Button);

export const NuveiCard = memo(({ safeCharge, sessionToken, transactionID, transactionMethod, countries }) => {
  const { application: { isEmbedded }  } = useSelector((state) => state);

  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState(null);
  const [paymentOptions, setPaymentOptions] = useState(null);
  const [emailAddress, setEmailAddress] = useState("");
  const [cardHolderName, setCardHolderName] = useState("");
  const [country, setCountry] = useState(DEFAULT_COUNTRY);
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [zip, setZip] = useState("");

  useEffect(() => {
    window.addEventListener('message', (message) => {
      try {
        const data = JSON.parse(message.data);
        if (data.threeDLoad === true) {
          setProcessing(false);
        }
      } catch(e) {}
    });
  }, []);

  useEffect(() => {
    if (paymentOptions) {
      // Handle page re-init (mainly on local dev)
      paymentOptions.cardNumber.destroy();
      paymentOptions.cardExpiry.destroy();
      paymentOptions.cardCvc.destroy();
    }

    const safeChargeFields = safeCharge.fields({});

    const cardNumber = safeChargeFields.create("ccNumber", { style: nuveiStyling });
    cardNumber.attach("#card-number");

    const cardExpiry = safeChargeFields.create("ccExpiration", { style: nuveiStyling });
    cardExpiry.attach("#card-expiry");

    const cardCvc = safeChargeFields.create("ccCvc", { style: nuveiStyling });
    cardCvc.attach("#card-cvc");

    setPaymentOptions({cardNumber, cardExpiry, cardCvc});
  }, [safeCharge]);

  const getCountryLabel = (option) => (option.country);

  const countryFromId = (id) => (countries.find((country) => (country.id === id)));

  const createPayment = () => {
    if (!paymentOptions) {
      return;
    }

    setError(null);
    setProcessing(true);

    const data = {
      sessionToken: sessionToken,
      cardHolderName: cardHolderName,
      paymentOption: paymentOptions.cardNumber,
      billingAddress: {
        email: emailAddress,
        country,
      }
    };

    if (isMoto(transactionMethod)) {
      data.billingAddress.address = address;
      data.billingAddress.city = city;
      data.billingAddress.zip = zip;
    }

    try {
      safeCharge.createPayment(
        data,
        (response) => {
          if (response.result === 'APPROVED') {
            paymentRedirect(true, transactionID, isEmbedded);
          } else {
            const error = parseNuveiError(response, transactionID);
            window.location.href = `${window.location.pathname}?error=defined&message=${error}`;
          }
        }
      );
    } catch (err) {
      Sentry.captureException(err);

      paymentRedirect(false, transactionID, isEmbedded);
    }
  };

  return (
    <Grid container spacing={2}>

      { processing && <FullScreenLoader open={true}>Processing Payment...</FullScreenLoader> }

      {error && (
        <Grid item xs={12}>
          <Typography className="error" style={{ color: '#cc0000' }}>{error}</Typography>
        </Grid>
      )}
      <Grid item xs={12}>
        <label className="sfc-label" htmlFor="card-number">Card number</label>
        <div id="card-number" className="input" />
      </Grid>
      <Grid item xs={12}>
        <label className="sfc-label" htmlFor="card-expiry">Expiry Date</label>
        <div id="card-expiry" className="input empty" />
      </Grid>
      <Grid item xs={12}>
        <label className="sfc-label" htmlFor="cardHolderName">Card Holder</label>
        <div className="item cardHolderName">
          <NuveiTextField
            variant={"outlined"}
            className="SfcCloneField"
            fullWidth
            required
            id="cardHolderName"
            placeholder="Card Holder"
            value={cardHolderName}
            onChange={(event) => setCardHolderName(event.target.value)}
          />
        </div>
      </Grid>
      <Grid item xs={12}>
        <label className="sfc-label" htmlFor="card-cvc">CVC</label>
        <div id="card-cvc" className="input empty" />
      </Grid>
      <Grid item xs={12}>
        <label className="sfc-label" htmlFor="emailAddress">Email Address</label>
        <div className="item emailAddress">
          <NuveiTextField
            variant={"outlined"}
            className="SfcCloneField"
            fullWidth
            required
            id="emailAddress"
            placeholder="Email Address"
            value={emailAddress}
            onChange={(event) => setEmailAddress(event.target.value)}
          />
        </div>
      </Grid>
      <Grid item xs={12}>
        <label className="sfc-label" htmlFor="country">Country</label>
        <div className="item country">
          <NuveiAutocomplete
            variant="outlined"
            className="SfcCloneField"
            fullWidth
            required
            id="country"
            placeholder="Country"
            options={countries}
            getOptionLabel={(value) => getCountryLabel(value) }
            value={countryFromId(country)}
            renderInput={(params) => (
              <NuveiTextField
                {...params}
                variant="outlined"
                className="SfcCloneField"
                fullWidth
              />)}
            onChange={(_, option) => setCountry(option.id)}
          />
        </div>
      </Grid>
      {isMoto(transactionMethod) && (
        <>
          <Grid item xs={12}>
            <label className="sfc-label" htmlFor="address">Address Line 1</label>
            <div className="item address">
              <NuveiTextField
                variant={"outlined"}
                className="SfcCloneField"
                fullWidth
                required
                id="address"
                placeholder="Address Line 1"
                value={address}
                onChange={(event) => setAddress(event.target.value)}
              />
            </div>
          </Grid>
          <Grid item xs={12}>
            <label className="sfc-label" htmlFor="city">City</label>
            <div className="item city">
              <NuveiTextField
                variant={"outlined"}
                className="SfcCloneField"
                fullWidth
                required
                id="city"
                placeholder="City"
                value={city}
                onChange={(event) => setCity(event.target.value)}
              />
            </div>
          </Grid>
          <Grid item xs={12}>
            <label className="sfc-label" htmlFor="zip">Postcode</label>
            <div className="item zip">
              <NuveiTextField
                variant={"outlined"}
                className="SfcCloneField"
                fullWidth
                required
                id="zip"
                placeholder="Postcode"
                value={zip}
                onChange={(event) => setZip(event.target.value)}
              />
            </div>
          </Grid>
        </>
      )}
      {!processing && (
        <Grid item xs={12}>
          <NuveiButton variant="contained" fullWidth className="SfcCloneButton" onClick={createPayment}>Pay Now</NuveiButton>
        </Grid>
      )}
    </Grid>
  );
});
