import { NUVEI_UPDATE_CONFIG, NUVEI_SET_LOADED } from "../../../action/provider/nuvei";

export const initialState = {
  sessionToken: null,
  merchantID: null,
  merchantSiteID: null,
  transactionID: null,
  loaded: false,
};

const NuveiReducer = (state = initialState, action) => {
  switch (action.type) {
    case NUVEI_UPDATE_CONFIG:
      return {
        ...state,
        ...action.payload,
        loaded: true,
      }

    case NUVEI_SET_LOADED:
      return {
        ...state,
        loaded: action.payload,
      }

    default:
      return state;
  }
};

export default NuveiReducer;
