/* eslint no-undef: 0 */

import React, { useEffect } from 'react';
import CardLogo from '../../../components/CardLogo';
import masterCard from '../../../assets/img/mc_hrz_pos.svg';
import visaCard from '../../../assets/img/visa.svg';
import {useDispatch, useSelector} from 'react-redux';
import {setJourneyProgress} from "../../../action/application";
import {RENDERED} from "../../../constants/journey-progress";
import CardLoading from "../../../components/CardLoading";
import * as Sentry from "@sentry/browser";
import { reload } from "../../../action/provider/total-processing";
import { isMoto as motoCheck } from '../../../constants/transaction-method';

export default function TotalProcessing({ transactionMethod }) {
  const { totalProcessing, application: { isEmbedded }  } = useSelector((state) => state);
  const { payment: { paymentDetails } } = useSelector((state) => state);

  const isMoto = motoCheck(transactionMethod);

  const dispatch = useDispatch();

  const onFormReady = () => {
    let fieldCount = 0;

    const postcodeElement = document.querySelector('.wpwl-control-postcode');
    if (postcodeElement && ((postcodeElement.value && postcodeElement.value !== '') || isMoto === false)) {
      postcodeElement.style.display = 'none';
      fieldCount += 1;
    }

    const countryElement = document.querySelector('.wpwl-control-country');
    if (countryElement && ((postcodeElement.value !== '' && countryElement.value && countryElement.value !== '') || isMoto === false)) {
      countryElement.style.display = 'none';
      fieldCount += 1;
    }

    const streetElement = document.querySelector('.wpwl-control-street1');
    if (streetElement && ((streetElement.value && streetElement.value !== '') || isMoto === false)) {
      streetElement.style.display = 'none';
      fieldCount += 1;
    }

    if (fieldCount === 3) {
      document.querySelector('.wpwl-group-billing').style.display = 'none';
    }
  };

  useEffect(() => {
    if (!totalProcessing.transactionID || !totalProcessing.checkoutID || totalProcessing.loaded === false) {
      return;
    }

    if (isMoto === false) {
      wpwlOptions.mandatoryBillingFields.country = false;
      wpwlOptions.mandatoryBillingFields.postcode = false;
      wpwlOptions.mandatoryBillingFields.street1 = false;
    }

    window.wpwlOptions.googlePay = {
      gatewayMerchantId: paymentDetails?.providers_payment_types?.TOTAL_PROCESSING?.merchant_id,
      buttonType: 'plain',
      buttonColor: 'white',
      billingAddressRequired: true,
      allowedCardNetworks:  ["MASTERCARD", "VISA"],
      merchantId: "BCR2DN4TYTMZRBBW",
    };

    if (paymentDetails?.customer?.id && paymentDetails?.customer?.address_1) {
      window.wpwlOptions.billingAddress = {
        street1: paymentDetails.customer.address_1,
        street2: paymentDetails.customer.address_2,
        city: paymentDetails.customer.city,
        postcode: paymentDetails.customer.post_code,
        country: paymentDetails.customer.country,
      };
    }

    if (isEmbedded === true) {
      window.wpwlOptions.paymentTarget = 'felloh-payment-sdk';
      window.wpwlOptions.shopperResultTarget = 'felloh-payment-sdk';
    } else {
      window.wpwlOptions.paymentTarget = 'total-processing-3ds';
    }

    const script = document.createElement("script");
    script.src = `${process.env.REACT_APP_TOTAL_PROCESSING_BASE_URL}v1/paymentWidgets.js?checkoutId=${totalProcessing.checkoutID}`;
    script.async = true;
    document.body.appendChild(script);

    dispatch(setJourneyProgress(RENDERED));

    return () => {
      document.body.removeChild(script);
    };
  }, [totalProcessing, isEmbedded]);

  useEffect(() => {
    window.wpwlOptions.onReadyIframeCommunication = onFormReady;

    window.wpwlOptions.onLoadThreeDIframe = (res) => {
      document.querySelector('.total-processing-3ds').style.display = 'block';
      document.querySelector('.wpwl-form-card').style.display = 'none';
    };

    if (typeof window.wpwlOptions?.brandDetectionPriority === 'object') {
      window.wpwlOptions.onError = function(error) {
        Sentry.captureException(error);
        let errorType = 'misc-error';

        if (error?.name === 'InvalidCheckoutIdError' || error?.name === 'PciIframeSubmitError') {
          errorType = 'timeout';
        }

        if (error?.name === 'InvalidCheckoutIdError' ) {
          window.wpwl.unload()

          setTimeout(() => {
            dispatch(reload());
          }, 250);
        } else if (isEmbedded === true) {
          window.location.href = `/embed/${paymentDetails.id}?error=${errorType}`;
        } else {
          window.location.href = `/${paymentDetails.id}?error=${errorType}`;
        }
      }
    }
  }, [window.wpwlOptions]);

  if (!totalProcessing.transactionID || totalProcessing.loaded === false) {
    return (
      <CardLoading />
    )
  }

  let processingURL = window.location.protocol + '//' + window.location.host + '/' + totalProcessing.transactionID + '/processing';

  if (isEmbedded === true) {
    processingURL = window.location.protocol + '//' + window.location.host + '/embed/' + totalProcessing.transactionID + '/processing';
  }

  return (
    <div id={`provider_total_processing${ isMoto === false ? ' threeds-payment-form' : ''}`}>
      <iframe title="3DS Iframe" className="total-processing-3ds" name="total-processing-3ds" style={{ display: 'none' }} />
      <form
        action={ processingURL }
        className="paymentWidgets"
        data-brands="VISA MASTER AMEX" />
      <div className="app_logo">
        <CardLogo width="20%" src={masterCard} padding="6px" />
        <CardLogo width="12%" src={visaCard} />
      </div>
    </div>
  );
};

