export const ONLINE = 'ONLINE'
export const MOTO_IN_PERSON = 'MOTO_IN_PERSON';
export const MOTO_OTHER = 'MOTO_OTHER';

export const TRANSACTION_METHODS = [
  ONLINE,
  MOTO_IN_PERSON,
  MOTO_OTHER,
];

export const ONLINE_TRANSACTION_METHODS = [
  ONLINE,
];

export const MOTO_TRANSACTION_METHODS = [
  MOTO_IN_PERSON,
  MOTO_OTHER,
];

export const validateTransactionMethod = (transactionMethod, manual = false, manualInPerson = false, transactionId = null) => {
  let method = null;

  // Try to fetch previous method from session storage
  if(typeof Storage !== "undefined" && transactionId && typeof sessionStorage.getItem(transactionId) === 'string') {
    try {
      const stored = JSON.parse(sessionStorage.getItem(transactionId));

      if (stored.method !== ONLINE) {
        method = stored.method;
      }
    } catch (e) {
      console.error(e);
    }
  }
  
  // If it's not set, try to determine it from the query params
  if (method === null) {
    if (manual === true) {
      method = MOTO_OTHER;
    } else if (manualInPerson === true) {
      method = MOTO_IN_PERSON;
    } else if (TRANSACTION_METHODS.includes(transactionMethod)) {
      method = transactionMethod;
    } else {
      method = ONLINE;
    }
  }

  // Store the method in session storage
  if(typeof Storage !== "undefined") {
    sessionStorage.setItem(transactionId, JSON.stringify({
      method: method,
    }));
  }

  return method;
};

export const isOnline = (transactionMethod) => (ONLINE_TRANSACTION_METHODS.includes(transactionMethod));

export const isMoto = (transactionMethod) => {
  return MOTO_TRANSACTION_METHODS.includes(transactionMethod);
};
