import { takeLatest, select, put } from 'redux-saga/effects';
import axios from "axios";

import { JOURNEY_PROGRESS, SET_ENUMS, LOAD, SET_TRANSACTION_ID } from "../../action/application";
import { getApplication } from "../selectors";
import API_ROUTES from "../../config/api";

function* embeddedJourneyUpdate() {
  let { journeyProgress } = yield select(getApplication);

  window.parent.postMessage(JSON.stringify({
    stage: journeyProgress,
  }), '*');
}

function* transactionIDUpdate(data) {
  let { transactionID } = yield select(getApplication);

  window.parent.postMessage(JSON.stringify({
    transactionID,
  }), '*');
}

function* fetchEnums() {
  const { data } = yield axios.get(API_ROUTES.enums['enums:get']());

  yield put({ type: SET_ENUMS, payload: data.data });
}

export default function* sagas() {
  yield takeLatest(JOURNEY_PROGRESS, embeddedJourneyUpdate);
  yield takeLatest(LOAD, fetchEnums);
  yield takeLatest(SET_TRANSACTION_ID, transactionIDUpdate);
}
